import React, { useEffect, useRef, useState } from 'react'

export const ChangesBackground = ({ anim, className }) => {
  const [elColor, setElColor] = useState(['#474458', '#474458', '#474458', '#474458', '#474458', '#474458', '#474458'])

  const interval = useRef(null)

  const getRandomNumber = (min, max) => {
    return Math.round(Math.random() * (max - min) + min)
  }

  const changeColorsHandler = () => {
    const randomItem = getRandomNumber(0, elColor.length - 1)
    setElColor(() => {
      const newArr = ['#474458', '#474458', '#474458', '#474458', '#474458', '#474458', '#474458']
      newArr[randomItem] = '#14C3DB'
      return newArr
    })
  }

  useEffect(() => {
    if (anim) {
      interval.current = setInterval(changeColorsHandler, 1500)
    } else {
      clearInterval(interval.current)
    }

    return () => {
      clearInterval(interval.current)
    }
  }, [anim])

  return (
    <svg
      className={className}
      width='650'
      height='331'
      viewBox='0 0 650 331'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g filter='url(#filter0_b_218_1532)'>
        <path d='M460 1V370' stroke='white' strokeOpacity='0.09' strokeWidth='2' strokeLinecap='round' />
      </g>
      <g filter='url(#filter1_b_218_1532)'>
        <path d='M220 1V370' stroke='white' strokeOpacity='0.09' strokeWidth='2' strokeLinecap='round' />
      </g>
      <g filter='url(#filter2_b_218_1532)'>
        <g clipPath='url(#clip0_218_1532)'>
          <rect y='41' width='450' height='40' rx='20' fill='white' fillOpacity='0.09' />
          <g filter='url(#filter3_b_218_1532)'>
            <rect x='12' y='53' width='77' height='16' rx='8' fill='white' fillOpacity='0.09' />
          </g>
          <path
            d='M102.055 55.7174C102.367 54.8218 103.633 54.8218 103.945 55.7174L104.652 57.7539C104.79 58.1489 105.158 58.4168 105.577 58.4253L107.732 58.4693C108.68 58.4886 109.071 59.6931 108.316 60.2659L106.598 61.5685C106.265 61.8211 106.124 62.2546 106.245 62.6549L106.869 64.7185C107.144 65.626 106.119 66.3705 105.341 65.8289L103.571 64.5975C103.228 64.3586 102.772 64.3586 102.429 64.5975L100.659 65.8289C99.8809 66.3705 98.8562 65.626 99.1308 64.7185L99.7551 62.6549C99.8762 62.2546 99.7354 61.8211 99.4021 61.5685L97.6841 60.2659C96.9285 59.6931 97.3199 58.4886 98.2678 58.4692L100.423 58.4253C100.842 58.4168 101.21 58.1489 101.348 57.7539L102.055 55.7174Z'
            fill={elColor[0]}
            style={{ transition: 'fill .5s ease-in' }}
          />
        </g>
      </g>
      <g filter='url(#filter4_b_218_1532)'>
        <g clipPath='url(#clip1_218_1532)'>
          <rect x='470' y='41' width='380' height='40' rx='20' fill='white' fillOpacity='0.09' />
          <g filter='url(#filter5_b_218_1532)'>
            <rect x='482' y='53' width='77' height='16' rx='8' fill='white' fillOpacity='0.09' />
          </g>
          <path
            d='M572.055 55.7174C572.367 54.8218 573.633 54.8218 573.945 55.7174L574.652 57.7539C574.79 58.1489 575.158 58.4168 575.577 58.4253L577.732 58.4693C578.68 58.4886 579.071 59.6931 578.316 60.2659L576.598 61.5685C576.265 61.8211 576.124 62.2546 576.245 62.6549L576.869 64.7185C577.144 65.626 576.119 66.3705 575.341 65.8289L573.571 64.5975C573.228 64.3586 572.772 64.3586 572.429 64.5975L570.659 65.8289C569.881 66.3705 568.856 65.626 569.131 64.7185L569.755 62.6549C569.876 62.2546 569.735 61.8211 569.402 61.5685L567.684 60.2659C566.929 59.6931 567.32 58.4886 568.268 58.4692L570.423 58.4253C570.842 58.4168 571.21 58.1489 571.348 57.7539L572.055 55.7174Z'
            fill={elColor[1]}
            style={{ transition: 'fill .5s ease-in' }}
          />
        </g>
      </g>
      <g filter='url(#filter6_b_218_1532)'>
        <g clipPath='url(#clip2_218_1532)'>
          <rect y='111' width='210' height='40' rx='20' fill='white' fillOpacity='0.09' />
          <g filter='url(#filter7_b_218_1532)'>
            <rect x='12' y='123' width='77' height='16' rx='8' fill='white' fillOpacity='0.09' />
          </g>
          <path
            d='M102.055 125.717C102.367 124.822 103.633 124.822 103.945 125.717L104.652 127.754C104.79 128.149 105.158 128.417 105.577 128.425L107.732 128.469C108.68 128.489 109.071 129.693 108.316 130.266L106.598 131.568C106.265 131.821 106.124 132.255 106.245 132.655L106.869 134.719C107.144 135.626 106.119 136.37 105.341 135.829L103.571 134.597C103.228 134.359 102.772 134.359 102.429 134.597L100.659 135.829C99.8809 136.37 98.8562 135.626 99.1308 134.719L99.7551 132.655C99.8762 132.255 99.7354 131.821 99.4021 131.568L97.6841 130.266C96.9285 129.693 97.3199 128.489 98.2678 128.469L100.423 128.425C100.842 128.417 101.21 128.149 101.348 127.754L102.055 125.717Z'
            fill={elColor[2]}
            style={{ transition: 'fill .5s ease-in' }}
          />
        </g>
      </g>
      <g filter='url(#filter8_b_218_1532)'>
        <g clipPath='url(#clip3_218_1532)'>
          <rect x='230' y='111' width='380' height='40' rx='20' fill='white' fillOpacity='0.09' />
          <g filter='url(#filter9_b_218_1532)'>
            <rect x='242' y='123' width='77' height='16' rx='8' fill='white' fillOpacity='0.09' />
          </g>
          <path
            d='M332.055 125.717C332.367 124.822 333.633 124.822 333.945 125.717L334.652 127.754C334.79 128.149 335.158 128.417 335.577 128.425L337.732 128.469C338.68 128.489 339.071 129.693 338.316 130.266L336.598 131.568C336.265 131.821 336.124 132.255 336.245 132.655L336.869 134.719C337.144 135.626 336.119 136.37 335.341 135.829L333.571 134.597C333.228 134.359 332.772 134.359 332.429 134.597L330.659 135.829C329.881 136.37 328.856 135.626 329.131 134.719L329.755 132.655C329.876 132.255 329.735 131.821 329.402 131.568L327.684 130.266C326.929 129.693 327.32 128.489 328.268 128.469L330.423 128.425C330.842 128.417 331.21 128.149 331.348 127.754L332.055 125.717Z'
            fill={elColor[3]}
            style={{ transition: 'fill .5s ease-in' }}
          />
        </g>
      </g>
      <g filter='url(#filter10_b_218_1532)'>
        <g clipPath='url(#clip4_218_1532)'>
          <rect x='230' y='181' width='456' height='40' rx='20' fill='white' fillOpacity='0.09' />
          <g filter='url(#filter11_b_218_1532)'>
            <rect x='242' y='193' width='77' height='16' rx='8' fill='white' fillOpacity='0.09' />
          </g>
          <path
            d='M332.055 195.717C332.367 194.822 333.633 194.822 333.945 195.717L334.652 197.754C334.79 198.149 335.158 198.417 335.577 198.425L337.732 198.469C338.68 198.489 339.071 199.693 338.316 200.266L336.598 201.568C336.265 201.821 336.124 202.255 336.245 202.655L336.869 204.719C337.144 205.626 336.119 206.37 335.341 205.829L333.571 204.597C333.228 204.359 332.772 204.359 332.429 204.597L330.659 205.829C329.881 206.37 328.856 205.626 329.131 204.719L329.755 202.655C329.876 202.255 329.735 201.821 329.402 201.568L327.684 200.266C326.929 199.693 327.32 198.489 328.268 198.469L330.423 198.425C330.842 198.417 331.21 198.149 331.348 197.754L332.055 195.717Z'
            fill={elColor[4]}
            style={{ transition: 'fill .5s ease-in' }}
          />
        </g>
      </g>
      <g filter='url(#filter12_b_218_1532)'>
        <g clipPath='url(#clip5_218_1532)'>
          <rect y='251' width='330' height='40' rx='20' fill='white' fillOpacity='0.09' />
          <g filter='url(#filter13_b_218_1532)'>
            <rect x='12' y='263' width='77' height='16' rx='8' fill='white' fillOpacity='0.09' />
          </g>
          <path
            d='M102.055 265.717C102.367 264.822 103.633 264.822 103.945 265.717L104.652 267.754C104.79 268.149 105.158 268.417 105.577 268.425L107.732 268.469C108.68 268.489 109.071 269.693 108.316 270.266L106.598 271.568C106.265 271.821 106.124 272.255 106.245 272.655L106.869 274.719C107.144 275.626 106.119 276.37 105.341 275.829L103.571 274.597C103.228 274.359 102.772 274.359 102.429 274.597L100.659 275.829C99.8809 276.37 98.8562 275.626 99.1308 274.719L99.7551 272.655C99.8762 272.255 99.7354 271.821 99.4021 271.568L97.6841 270.266C96.9285 269.693 97.3199 268.489 98.2678 268.469L100.423 268.425C100.842 268.417 101.21 268.149 101.348 267.754L102.055 265.717Z'
            fill={elColor[5]}
            style={{ transition: 'fill .5s ease-in' }}
          />
        </g>
      </g>
      <g filter='url(#filter14_b_218_1532)'>
        <g clipPath='url(#clip6_218_1532)'>
          <rect x='350' y='251' width='380' height='40' rx='20' fill='white' fillOpacity='0.09' />
          <g filter='url(#filter15_b_218_1532)'>
            <rect x='362' y='263' width='77' height='16' rx='8' fill='white' fillOpacity='0.09' />
          </g>
          <path
            d='M452.055 265.717C452.367 264.822 453.633 264.822 453.945 265.717L454.652 267.754C454.79 268.149 455.158 268.417 455.577 268.425L457.732 268.469C458.68 268.489 459.071 269.693 458.316 270.266L456.598 271.568C456.265 271.821 456.124 272.255 456.245 272.655L456.869 274.719C457.144 275.626 456.119 276.37 455.341 275.829L453.571 274.597C453.228 274.359 452.772 274.359 452.429 274.597L450.659 275.829C449.881 276.37 448.856 275.626 449.131 274.719L449.755 272.655C449.876 272.255 449.735 271.821 449.402 271.568L447.684 270.266C446.929 269.693 447.32 268.489 448.268 268.469L450.423 268.425C450.842 268.417 451.21 268.149 451.348 267.754L452.055 265.717Z'
            fill={elColor[6]}
            style={{ transition: 'fill .5s ease-in' }}
          />
          <path
            d='M452.055 265.717C452.367 264.822 453.633 264.822 453.945 265.717L454.652 267.754C454.79 268.149 455.158 268.417 455.577 268.425L457.732 268.469C458.68 268.489 459.071 269.693 458.316 270.266L456.598 271.568C456.265 271.821 456.124 272.255 456.245 272.655L456.869 274.719C457.144 275.626 456.119 276.37 455.341 275.829L453.571 274.597C453.228 274.359 452.772 274.359 452.429 274.597L450.659 275.829C449.881 276.37 448.856 275.626 449.131 274.719L449.755 272.655C449.876 272.255 449.735 271.821 449.402 271.568L447.684 270.266C446.929 269.693 447.32 268.489 448.268 268.469L450.423 268.425C450.842 268.417 451.21 268.149 451.348 267.754L452.055 265.717Z'
            fill={elColor[7]}
            style={{ transition: 'fill .5s ease-in' }}
          />
        </g>
      </g>
      <defs>
        <filter
          id='filter0_b_218_1532'
          x='434'
          y='-25'
          width='52'
          height='421'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feGaussianBlur in='BackgroundImageFix' stdDeviation='12.5' />
          <feComposite in2='SourceAlpha' operator='in' result='effect1_backgroundBlur_218_1532' />
          <feBlend mode='normal' in='SourceGraphic' in2='effect1_backgroundBlur_218_1532' result='shape' />
        </filter>
        <filter
          id='filter1_b_218_1532'
          x='194'
          y='-25'
          width='52'
          height='421'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feGaussianBlur in='BackgroundImageFix' stdDeviation='12.5' />
          <feComposite in2='SourceAlpha' operator='in' result='effect1_backgroundBlur_218_1532' />
          <feBlend mode='normal' in='SourceGraphic' in2='effect1_backgroundBlur_218_1532' result='shape' />
        </filter>
        <filter
          id='filter2_b_218_1532'
          x='-25'
          y='16'
          width='500'
          height='90'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feGaussianBlur in='BackgroundImageFix' stdDeviation='12.5' />
          <feComposite in2='SourceAlpha' operator='in' result='effect1_backgroundBlur_218_1532' />
          <feBlend mode='normal' in='SourceGraphic' in2='effect1_backgroundBlur_218_1532' result='shape' />
        </filter>
        <filter
          id='filter3_b_218_1532'
          x='-13'
          y='28'
          width='127'
          height='66'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feGaussianBlur in='BackgroundImageFix' stdDeviation='12.5' />
          <feComposite in2='SourceAlpha' operator='in' result='effect1_backgroundBlur_218_1532' />
          <feBlend mode='normal' in='SourceGraphic' in2='effect1_backgroundBlur_218_1532' result='shape' />
        </filter>
        <filter
          id='filter4_b_218_1532'
          x='445'
          y='16'
          width='430'
          height='90'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feGaussianBlur in='BackgroundImageFix' stdDeviation='12.5' />
          <feComposite in2='SourceAlpha' operator='in' result='effect1_backgroundBlur_218_1532' />
          <feBlend mode='normal' in='SourceGraphic' in2='effect1_backgroundBlur_218_1532' result='shape' />
        </filter>
        <filter
          id='filter5_b_218_1532'
          x='457'
          y='28'
          width='127'
          height='66'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feGaussianBlur in='BackgroundImageFix' stdDeviation='12.5' />
          <feComposite in2='SourceAlpha' operator='in' result='effect1_backgroundBlur_218_1532' />
          <feBlend mode='normal' in='SourceGraphic' in2='effect1_backgroundBlur_218_1532' result='shape' />
        </filter>
        <filter
          id='filter6_b_218_1532'
          x='-25'
          y='86'
          width='260'
          height='90'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feGaussianBlur in='BackgroundImageFix' stdDeviation='12.5' />
          <feComposite in2='SourceAlpha' operator='in' result='effect1_backgroundBlur_218_1532' />
          <feBlend mode='normal' in='SourceGraphic' in2='effect1_backgroundBlur_218_1532' result='shape' />
        </filter>
        <filter
          id='filter7_b_218_1532'
          x='-13'
          y='98'
          width='127'
          height='66'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feGaussianBlur in='BackgroundImageFix' stdDeviation='12.5' />
          <feComposite in2='SourceAlpha' operator='in' result='effect1_backgroundBlur_218_1532' />
          <feBlend mode='normal' in='SourceGraphic' in2='effect1_backgroundBlur_218_1532' result='shape' />
        </filter>
        <filter
          id='filter8_b_218_1532'
          x='205'
          y='86'
          width='430'
          height='90'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feGaussianBlur in='BackgroundImageFix' stdDeviation='12.5' />
          <feComposite in2='SourceAlpha' operator='in' result='effect1_backgroundBlur_218_1532' />
          <feBlend mode='normal' in='SourceGraphic' in2='effect1_backgroundBlur_218_1532' result='shape' />
        </filter>
        <filter
          id='filter9_b_218_1532'
          x='217'
          y='98'
          width='127'
          height='66'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feGaussianBlur in='BackgroundImageFix' stdDeviation='12.5' />
          <feComposite in2='SourceAlpha' operator='in' result='effect1_backgroundBlur_218_1532' />
          <feBlend mode='normal' in='SourceGraphic' in2='effect1_backgroundBlur_218_1532' result='shape' />
        </filter>
        <filter
          id='filter10_b_218_1532'
          x='205'
          y='156'
          width='506'
          height='90'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feGaussianBlur in='BackgroundImageFix' stdDeviation='12.5' />
          <feComposite in2='SourceAlpha' operator='in' result='effect1_backgroundBlur_218_1532' />
          <feBlend mode='normal' in='SourceGraphic' in2='effect1_backgroundBlur_218_1532' result='shape' />
        </filter>
        <filter
          id='filter11_b_218_1532'
          x='217'
          y='168'
          width='127'
          height='66'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feGaussianBlur in='BackgroundImageFix' stdDeviation='12.5' />
          <feComposite in2='SourceAlpha' operator='in' result='effect1_backgroundBlur_218_1532' />
          <feBlend mode='normal' in='SourceGraphic' in2='effect1_backgroundBlur_218_1532' result='shape' />
        </filter>
        <filter
          id='filter12_b_218_1532'
          x='-25'
          y='226'
          width='380'
          height='90'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feGaussianBlur in='BackgroundImageFix' stdDeviation='12.5' />
          <feComposite in2='SourceAlpha' operator='in' result='effect1_backgroundBlur_218_1532' />
          <feBlend mode='normal' in='SourceGraphic' in2='effect1_backgroundBlur_218_1532' result='shape' />
        </filter>
        <filter
          id='filter13_b_218_1532'
          x='-13'
          y='238'
          width='127'
          height='66'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feGaussianBlur in='BackgroundImageFix' stdDeviation='12.5' />
          <feComposite in2='SourceAlpha' operator='in' result='effect1_backgroundBlur_218_1532' />
          <feBlend mode='normal' in='SourceGraphic' in2='effect1_backgroundBlur_218_1532' result='shape' />
        </filter>
        <filter
          id='filter14_b_218_1532'
          x='325'
          y='226'
          width='430'
          height='90'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feGaussianBlur in='BackgroundImageFix' stdDeviation='12.5' />
          <feComposite in2='SourceAlpha' operator='in' result='effect1_backgroundBlur_218_1532' />
          <feBlend mode='normal' in='SourceGraphic' in2='effect1_backgroundBlur_218_1532' result='shape' />
        </filter>
        <filter
          id='filter15_b_218_1532'
          x='337'
          y='238'
          width='127'
          height='66'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feGaussianBlur in='BackgroundImageFix' stdDeviation='12.5' />
          <feComposite in2='SourceAlpha' operator='in' result='effect1_backgroundBlur_218_1532' />
          <feBlend mode='normal' in='SourceGraphic' in2='effect1_backgroundBlur_218_1532' result='shape' />
        </filter>
        <clipPath id='clip0_218_1532'>
          <rect y='41' width='450' height='40' rx='20' fill='white' />
        </clipPath>
        <clipPath id='clip1_218_1532'>
          <rect x='470' y='41' width='380' height='40' rx='20' fill='white' />
        </clipPath>
        <clipPath id='clip2_218_1532'>
          <rect y='111' width='210' height='40' rx='20' fill='white' />
        </clipPath>
        <clipPath id='clip3_218_1532'>
          <rect x='230' y='111' width='380' height='40' rx='20' fill='white' />
        </clipPath>
        <clipPath id='clip4_218_1532'>
          <rect x='230' y='181' width='456' height='40' rx='20' fill='white' />
        </clipPath>
        <clipPath id='clip5_218_1532'>
          <rect y='251' width='330' height='40' rx='20' fill='white' />
        </clipPath>
        <clipPath id='clip6_218_1532'>
          <rect x='350' y='251' width='380' height='40' rx='20' fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}
