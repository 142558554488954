import React, { FC, useEffect, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import styles from './WhatDifference.module.scss'
import clsx from 'clsx'

export const WhatDifference: FC = () => {
  const [ref, inView, entry] = useInView({ threshold: 1 })
  const [isAnimActivated, setIsAnimActivated] = useState(false)

  useEffect(() => {
    if (inView && !isAnimActivated) {
      setIsAnimActivated(true)
    }
  }, [inView])

  return (
    <div ref={ref} className={styles.wrapper}>
      <h2 className={clsx(styles.title, isAnimActivated && styles.animActivated)}>
        <span className={styles.transparent}>узнайте,</span>
        <span className={styles.normal}> стоит ли резать бюджет, </span>
        <span className={styles.transparent}>
          когда расставаться
          <br />с подрядчиком,{' '}
        </span>
        <span className={styles.normal}>
          почему скорость дороже денег
          <br />
        </span>
        <span className={styles.transparent}> и ещё десятки инсайтов</span>
      </h2>
    </div>
  )
}
