import { Excerption } from '@components/Excerption'
import { SubscribeComponent } from '@components/SubscribeComponent'
import React, { FC, useState } from 'react'
import styles from './PlanningAndControl.module.scss'
import { ReactComponent as StarsEmoji } from '../../../../assets/icons/starsEmoji.svg'
import useWindowSize from '@lib/hooks/useWindowSizeHook'

export const PlanningAndControl: FC = () => {
  const [isEmailSent, setIsEmailSent] = useState(false)
  const { width } = useWindowSize()
  return (
    <div className={styles.wrapper}>
      {!isEmailSent ? (
        <>
          <h6 className={styles.subTitle}>узнайте, как изменилось</h6>
          <h5 className={styles.title}>планирование</h5>
          <div className={styles.titleWrapper}>
            <p className={styles.desc}>
              вы нам — свою почту, <br />
              мы вам — исследование
            </p>
            <h5 className={styles.title}>и контроль</h5>
          </div>
          <div className={styles.subscribeWrapper}>
            <SubscribeComponent btnTitle='узнать' afterSendHandler={() => setIsEmailSent(true)} />
          </div>
          <div className={styles.excerptionWrapper}>
            <Excerption author={{ name: 'Екатерина Власенко', position: 'исполнительный директор Стратегия М' }}>
              <p className={styles.excerptionText}>
                Критичнее отношусь к&nbsp;аргументации контрагентов. Предложение и&nbsp;стоимость не&nbsp;актуальны без
                кейсов, отзывов, тестовых решений.
              </p>
            </Excerption>
          </div>
        </>
      ) : (
        <>
          <div className={styles.success}>
            <h5 className={styles.title}>спасибо</h5>
            <h5 className={styles.title}>за заявку</h5>
            <div className={styles.successDescWrapper}>
              <p className={styles.desc}>
                мы уже выслали исследование
                <br />
                на указанную почту
              </p>
              <StarsEmoji className={styles.successIcons} />
            </div>
          </div>
          <div className={styles.excerptionWrapper}>
            <Excerption author={{ name: 'Екатерина Власенко', position: 'исполнительный директор Стратегия М' }}>
              <p className={styles.excerptionText}>
                Критичнее отношусь к&nbsp;аргументации контрагентов. Предложение и&nbsp;стоимость не&nbsp;актуальны без
                кейсов, отзывов, тестовых решений.
              </p>
            </Excerption>
          </div>
        </>
      )}
    </div>
  )
}
