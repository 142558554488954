import { SubscribeComponent } from '@components/SubscribeComponent'
import React, { FC, useState } from 'react'
import styles from './WorkWithContractors.module.scss'
import { ReactComponent as StarsEmoji } from '../../../../assets/icons/starsEmoji.svg'
import useWindowSize from '@lib/hooks/useWindowSizeHook'

export const WorkWithContractors: FC = () => {
  const [isEmailSent, setIsEmailSent] = useState(false)
  const { width } = useWindowSize()
  return (
    <div className={styles.wrapper}>
      {!isEmailSent ? (
        <>
          <h6 className={styles.subTitle}>узнайте, как изменилась работа</h6>
          <h5 className={styles.title}>с подрядчиками</h5>
          <div className={styles.textWrapper}>
            <p className={styles.desc}>
              вы нам — свою почту,
              {width > 600 && <br />}
              мы вам — исследование
            </p>
            <h5 className={styles.title}>и их выбор</h5>
          </div>
          <div className={styles.subscribeWrapper}>
            <SubscribeComponent btnTitle='узнать' afterSendHandler={() => setIsEmailSent(true)} />
          </div>
        </>
      ) : (
        <div className={styles.success}>
          <h5 className={styles.title}>спасибо</h5>
          <h5 className={styles.title}>за заявку</h5>
          <div className={styles.successDescWrapper}>
            <p className={styles.desc}>
              мы уже выслали исследование
              <br />
              на указанную почту
            </p>
            <StarsEmoji className={styles.successIcons} />
          </div>
        </div>
      )}
    </div>
  )
}
