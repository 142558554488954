import React, { FC } from 'react'
import styles from './Header.module.scss'
import { ReactComponent as AntroLogo } from '../../assets/icons/antroLogo.svg'
import clsx from 'clsx'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import useWindowSize from '@lib/hooks/useWindowSizeHook'

const SCROLL_OFFSET = 200

export const Header: FC<{ isScrollingStyle: boolean; CTADrawerOpenHandler: () => void }> = ({
  isScrollingStyle,
  CTADrawerOpenHandler,
}) => {
  const { width } = useWindowSize()
  return (
    <div className={clsx(styles.wrapper, isScrollingStyle && styles.onScroll)}>
      <div className={clsx(styles.container, isScrollingStyle && styles.onScroll)}>
        <div className={styles.leftSide}>
          <div className={styles.logoWrapper}>
            <AntroLogo className={styles.logo} />
            <div className={styles.logoDesc}>
              опытоцентричное
              <br />
              агентство
            </div>
          </div>
          <div className={styles.mojo}>ex: Mojo</div>
        </div>
        {width > 1050 && (
          <div className={styles.rightSide}>
            <nav className={clsx(styles.navLinks, isScrollingStyle && styles.onScroll)}>
              <ul className={styles.navList}>
                <li className={styles.navItem}>
                  <AnchorLink offset={SCROLL_OFFSET} className={styles.anchorLink} href='#reason'>
                    причины
                  </AnchorLink>
                </li>
                <li className={styles.navItem}>
                  <AnchorLink offset={SCROLL_OFFSET} className={styles.anchorLink} href='#whoBenefits'>
                    кому будет полезно
                  </AnchorLink>
                </li>
                <li className={styles.navItem}>
                  <AnchorLink offset={SCROLL_OFFSET} className={styles.anchorLink} href='#researchPart'>
                    часть исследования
                  </AnchorLink>
                </li>
                <li className={styles.navItem}>
                  <AnchorLink offset={SCROLL_OFFSET} className={styles.anchorLink} href='#about'>
                    о нас
                  </AnchorLink>
                </li>
              </ul>
            </nav>
            <button className={clsx(styles.btn, isScrollingStyle && styles.onScroll)} onClick={CTADrawerOpenHandler}>
              получить pdf
            </button>
          </div>
        )}
      </div>
    </div>
  )
}
