import React, { FC } from 'react'
import styles from './Strategy.module.scss'
import { ReactComponent as SneakerEmoji } from '../../assets/icons/sneakerEmoji.svg'
import { ReactComponent as TreeEmoji } from '../../assets/icons/treeEmoji.svg'
import { ReactComponent as PriceEmoji } from '../../assets/icons/priceEmoji.svg'
import rocket from '../../assets/images/strategyRocket.png'
import Tooltip from 'antd/es/tooltip'
import useWindowSize from '@lib/hooks/useWindowSizeHook'

export const Strategy: FC = () => {
  const { width } = useWindowSize()
  return (
    <div className={styles.wrapper}>
      <div className={styles.leftSide}>
        <div className={styles.imgContainer}>
          <div className={styles.imgWrapper}>
            <img className={styles.rocketImage} src={rocket} alt='Ракета' />
          </div>
        </div>
        <div className={styles.textBlock}>
          <div className={styles.row}>
            <div id='welcomeTariff' className={styles.pseudoBtnsWrapper}>
              <div className={styles.pseudoBtn}>
                <SneakerEmoji className={styles.pseudoBtnIcon} />
                <span className={styles.pseudoBtnText}>1-2 недели</span>
              </div>
              <div className={styles.pseudoBtn}>
                <TreeEmoji className={styles.pseudoBtnIcon} />
                <span className={styles.pseudoBtnText}>успеем до нг</span>
              </div>
              <div className={styles.pseudoBtn}>
                <PriceEmoji className={styles.pseudoBtnIcon} />
                <span className={styles.pseudoBtnText}>welcome-тариф</span>
                <Tooltip
                  align={{ targetOffset: [-10, 20] }}
                  overlayStyle={{
                    borderRadius: '40px',
                    background: '#0A0620',
                  }}
                  trigger={['hover', 'click']}
                  overlayInnerStyle={{
                    background: '#0A0620',
                    padding: 30,
                    fontFamily: 'Graphik LC Web',
                    fontStyle: 'normal',
                    fontWeight: '400',
                    fontSize: width < 800 ? '16px' : '24px',
                    lineHeight: '100%',
                    color: '#FFFFFF',
                    width: 416,
                    borderRadius: '40px',
                  }}
                  placement='rightBottom'
                  title='заводим новых друзей, поэтому делимся экспертизой по дружеской цене'
                >
                  <button className={styles.wtfBtn}>?</button>
                </Tooltip>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.rightSide}>
        <ul className={styles.rightSideList}>
          <li className={styles.rightSideItem}>
            проведём UX-аудит: <span className={styles.transparent}>подсветим проблемы и&nbsp;слабые места</span>
          </li>
          <li className={styles.rightSideItem}>приоритизируем задачи и&nbsp;гипотезы</li>
          <li className={styles.rightSideItem}>
            продумаем, как поднять конверсию&nbsp;
            <span className={styles.transparent}>на&nbsp;каждом шаге воронки</span>
          </li>
          <li className={styles.rightSideItem}>разработаем план развития </li>
        </ul>
      </div>
      <a
        className={styles.linkToLanding}
        href='https://antro.cx/?talk_about_open_step3=true'
        target='_blank'
        rel='noreferrer'
      >
        поооооогнаааали
      </a>
    </div>
  )
}
