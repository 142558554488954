import React, { FC, useEffect, useState } from 'react'
import styles from './Crisis.module.scss'
import { useInView } from 'react-intersection-observer'
import { CrisisBackground } from './background/CrisisBackground'

export const Crisis: FC = () => {
  const [ref, inView] = useInView()
  const [isAnimActivated, setIsAnimActivated] = useState(false)

  useEffect(() => {
    if (inView && !isAnimActivated) {
      setIsAnimActivated(true)
    } else {
      setIsAnimActivated(false)
    }
  }, [inView])
  return (
    <div ref={ref} className={styles.wrapper}>
      <CrisisBackground anim={isAnimActivated} className={styles.background} />
      <h5 className={styles.title}>
        все{' '}
        <span className={styles.pink}>
          столкнулись
          <br />с кризисом
        </span>
      </h5>
      <p className={styles.desc}>
        процессы в компаниях
        <br />
        изменились, высший
        <br />
        менеджмент отреагировал
        <br />
        на неопределённость
      </p>
    </div>
  )
}
