import React, { FC, useEffect, useState } from 'react'
import styles from './HelpToBusiness.module.scss'
import clsx from 'clsx'
import { ReactComponent as NbLogo } from '../../assets/icons/nbLogo.svg'
import { ReactComponent as HyperLogo } from '../../assets/icons/hyperpcLogo.svg'
import { ReactComponent as IddisLogo } from '../../assets/icons/iddisLogo.svg'
import { ReactComponent as NoteBookEmoji } from '../../assets/icons/noteBookEmoji.svg'
import { ReactComponent as LetterEmoji } from '../../assets/icons/letterEmoji.svg'
import { ReactComponent as SalutEmoji } from '../../assets/icons/salutEmoji.svg'
import { ReactComponent as LinkIcon } from '../../assets/icons/linkIcon.svg'
import { useInView } from 'react-intersection-observer'
import useWindowSize from '@lib/hooks/useWindowSizeHook'
// import { ReactComponent as PlayBtn } from '../../assets/icons/playBtn.svg'
// import { ReactComponent as PauseBtn } from '../../assets/icons/pauseBtn.svg'
// import ReactPlayer from 'react-player'
/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
/* @ts-ignore */
// import testVideo from '../../assets/video/testvideo.mp4'

export const HelpToBusiness: FC = () => {
  const [ref, inView, entry] = useInView()
  const [isPlaying, setIsPlaying] = useState(false)
  const [isAnimActivated, setIsAnimActivated] = useState(false)
  const { width } = useWindowSize()

  useEffect(() => {
    if (inView && !isAnimActivated) {
      setIsAnimActivated(true)
    }
  }, [inView])

  return (
    <div ref={ref} className={styles.wrapper} id='about'>
      <div className={styles.titleTextWrapper}>
        {/* <div className={styles.videoBlock}>
        <div className={styles.playBtnVideoWrapper}>
          <div className={styles.playerWrapper}>
            <ReactPlayer
              className={styles.player}
              width='auto'
              height='100%'
              url={testVideo}
              playing={isPlaying}
              controls={false}
              loop
              // onProgress={(e)=>dispatch(handleProgress(e))}
              config={{
                file: {
                  attributes: {
                    controlsList: 'nofullscreen',
                  },
                },
              }}
            />
          </div>
          {isPlaying ? (
            <PauseBtn className={styles.playBtn} onClick={() => setIsPlaying(false)} />
          ) : (
            <PlayBtn className={styles.playBtn} onClick={() => setIsPlaying(true)} />
          )}
        </div>

        <div className={styles.authorWrapper}>
          <h5 className={styles.authorText}>
            как мы помогаем компаниям
            <br />
            проходить через кризис
          </h5>
          <p className={styles.authorName}>
            костя щербина,
            <br />
            фаундер Antro
          </p>
        </div>
      </div> */}
        <h5 className={styles.title}>поможем бизнесу</h5>
        <h5 className={styles.title}>пережить {width <= 800 && 'кризис'}</h5>
        <div className={styles.textWrapper}>
          {width > 800 && <h5 className={styles.title}>кризис</h5>}
          {width <= 800 ? (
            <p className={styles.desc}>
              с&nbsp;помощью изменений в&nbsp;процессах, лучших практик, исследований рынка и&nbsp;клиентов
            </p>
          ) : (
            <p className={styles.desc}>
              с&nbsp;помощью изменений в&nbsp;процессах, лучших
              <br />
              практик, исследований рынка и&nbsp;клиентов
            </p>
          )}
        </div>

        {width > 800 && (
          <>
            <div className={clsx(styles.popupApps, isAnimActivated && styles.animActivated)}>
              <NoteBookEmoji className={styles.popupIcon} />{' '}
              <span className={styles.popupText}>сайты и приложения</span>
            </div>
            <div className={clsx(styles.popupResearch, isAnimActivated && styles.animActivated)}>
              <LetterEmoji className={styles.popupIcon} /> <span className={styles.popupText}>исследования</span>
            </div>
            <div className={clsx(styles.popupBranding, isAnimActivated && styles.animActivated)}>
              <SalutEmoji className={styles.popupIcon} /> <span className={styles.popupText}>брендинг и айдентика</span>
            </div>
          </>
        )}
      </div>
      <div className={styles.casesWrapper}>
        <div className={clsx(styles.case, styles.nb)}>
          <div className={styles.caseLogoWrapper}>
            <NbLogo className={styles.caseLogo} />
          </div>
          <p className={styles.caseDesc}>
            спроектировали и&nbsp;задизайнили корзину с&nbsp;учётом сложной механики взаимодействия разных складов
            и&nbsp;продолжаем редизайн
          </p>
        </div>
        <div className={clsx(styles.case, styles.hyperpc)}>
          <div className={styles.caseLogoWrapper}>
            <HyperLogo className={styles.caseLogo} />
          </div>
          <p className={styles.caseDesc}>
            провели ребрендинг и проектируем новый сайт по&nbsp;лучшим практикам рынка, учитывая данные веб-аналитикии
            исследований
          </p>
        </div>
        <div className={clsx(styles.case, styles.iddis)}>
          <div className={styles.caseHeader}>
            <div className={styles.caseLogoWrapper}>
              <IddisLogo className={styles.caseLogo} />
            </div>
            <a
              href='https://antro.cx/cases/228-iddis-dizain-internet-magazina-santekhniki'
              target='_blank'
              rel='noreferrer'
              className={styles.iddisLink}
            >
              <LinkIcon className={styles.iddisLinkIcon} />
            </a>
          </div>

          <p className={styles.caseDesc}>
            задизайнили интернет-магазин топ-5 брендов сантехники в&nbsp;РФ по&nbsp;гайдам Baymard и&nbsp;NNg. Изучили
            новый сегмент аудитории и спроектировали для него личный кабинет
          </p>
        </div>
      </div>
      <a className={styles.casesLInk} href='https://antro.cx/cases' target='_blank' rel='noreferrer'>
        <span>смотреть все кейсы</span>
      </a>
    </div>
  )
}
