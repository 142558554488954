import { Excerption } from '@components/Excerption'
import useWindowSize from '@lib/hooks/useWindowSizeHook'
import React, { FC } from 'react'
import styles from './Priorites.module.scss'

export const Priorites: FC<{ CTADrawerOpenHandler: () => void }> = ({ CTADrawerOpenHandler }) => {
  const { width } = useWindowSize()
  return (
    <div className={styles.wrapper}>
      <h5 className={styles.title}>приоритеты</h5>
      <div className={styles.row}>
        <div className={styles.speedBlock}>
          <h5 className={styles.speedTitle}>скорость</h5>
          <p className={styles.speedDesc}>стала ключевой метрикой</p>
        </div>
        <p className={styles.rightSideDesc}>
          инсайты основаны на данных
          <br />
          от опрошенных компаний
        </p>
      </div>
      <div className={styles.row}>
        <div className={styles.companies}>
          <h6 className={styles.companiesSubTitle}>до</h6>
          <h5 className={styles.companiesTitle}>25%</h5>
          <p className={styles.companiesDesc}>
            компании готовы
            <br />
            переплачивать,
            <br />
            чтобы не терять
            <br />в скорости и качестве
          </p>
        </div>
        <button onClick={CTADrawerOpenHandler} className={styles.getPdfBtn}>
          <h6 className={styles.getPdfBtnTitle}>+3</h6>
          <p className={styles.getPdfBtnDesc}>
            инсайта
            <br />в полной{width <= 600 && <br />} версии
          </p>
          <div className={styles.getPdfPseudoBtn}>получить {width > 600 && 'pdf'}</div>
        </button>
      </div>
      <div className={styles.excerptionWrapper}>
        <Excerption author={{ name: 'Денис Турапов', position: 'менеджер цифровых продуктов МногоСна' }}>
          <p className={styles.excerptionText}>
            Пересмотрели подход к&nbsp;приоритезации задач: сейчас важна скорость реализации и&nbsp;максимальный эффект
            от&nbsp;задачи
          </p>
        </Excerption>
      </div>
    </div>
  )
}
