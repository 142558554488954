import React, { FC } from 'react'
import styles from './BudgetChanges.module.scss'
import donutChart from '../../../../assets/images/donutChart.png'
import useWindowSize from '@lib/hooks/useWindowSizeHook'

export const BudgetChanges: FC<{ CTADrawerOpenHandler: () => void }> = ({ CTADrawerOpenHandler }) => {
  const { width } = useWindowSize()
  return (
    <div className={styles.wrapper}>
      <h5 className={styles.title}>
        как изменилось
        <br />
        распределение бюджета
      </h5>
      <div className={styles.budgetPercentageWrapper}>
        <div className={styles.budgetPercentageTitleWrapper}>
          <p className={styles.budgetPercentageDesc}>на</p>
          <h6 className={styles.budgetPercentageTitle}>30-60%</h6>
          <p className={styles.budgetPercentageSubDesc}>снизился бюджет на дизайн и&nbsp;разработку</p>
        </div>
        <div className={styles.chartWrapper}>
          <div className={styles.chartDonutWrapper}>
            <img src={donutChart} className={styles.chartDonut} />
          </div>
          <div className={styles.percentageWrapper}>
            <div className={styles.percentageContainer}>
              <h6 className={styles.chartPercentage}>~10%</h6>
              <p className={styles.chartPercentageDesc}>не&nbsp;сокращали</p>
            </div>
            <div className={styles.percentageContainer}>
              <h6 className={styles.chartPercentage}>~3%</h6>
              <p className={styles.chartPercentageDesc}>увеличили</p>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.bottomBlocksWrapper}>
        <div className={styles.rightBlockWrapper}>
          <div className={styles.investWrapper}>
            <h6 className={styles.investTitle}>{'>'} 30%</h6>
            <p className={styles.investDesc}>
              инвестируют в новые
              <br />
              продукты, бренды,
              <br />
              линейки и товары
            </p>
          </div>
          <p className={styles.incitesDesc}>
            инсайты основаны на данных
            <br />
            от опрошенных компаний
          </p>
        </div>
        <button onClick={CTADrawerOpenHandler} className={styles.getPdfBtn}>
          <h6 className={styles.getPdfBtnTitle}>+6</h6>
          <p className={styles.getPdfBtnDesc}>
            инсайтов
            <br />в полной версии
          </p>
          <div className={styles.getPdfPseudoBtn}>получить {width > 600 && 'pdf'}</div>
        </button>
      </div>
    </div>
  )
}
