import React, { FC, useEffect, useState } from 'react'
import styles from './DrawerContent.module.scss'
import { ReactComponent as CloseBtn } from '../../assets/icons/closeBtnIcon.svg'
import { ReactComponent as StarsEmoji } from '../../assets/icons/starsEmoji.svg'
import useWindowSize from '@lib/hooks/useWindowSizeHook'
import { api } from '@app/api'
import { validateEmail } from '@lib/helpers/validateEmail'
import clsx from 'clsx'

interface DataInterface {
  url: string
  mail: string
  // eslint-disable-next-line camelcase
  send_pdf?: boolean
  research?: boolean
  presentation?: boolean
}

export const DrawerContent: FC<{ closeCTADrawerHandler: () => void }> = ({ closeCTADrawerHandler }) => {
  const { width } = useWindowSize()
  const [emailValue, setEmailValue] = useState('')
  const [researchSwitchValue, setResearchSwitchValue] = useState(false)
  const [successfully, setSuccessfully] = useState(false)
  const [isEmailValid, setIsEmailValid] = useState(false)
  const [isDirty, setIsDirty] = useState(false)

  const onClickHandler = async () => {
    if (validateEmail(emailValue)) {
      const url = window.location.href

      const data: DataInterface = {
        url,
        mail: emailValue,
        send_pdf: true,
      }
      if (researchSwitchValue) {
        data.research = true
      }

      const response = await api.post<any>(`subscribingemail/`, data)
      setSuccessfully(true)
    } else {
      setIsDirty(true)
      setIsEmailValid(false)
    }
  }

  useEffect(() => {
    if (validateEmail(emailValue) && isDirty) {
      setIsEmailValid(true)
    } else {
      setIsEmailValid(false)
    }
  }, [emailValue, isDirty])

  return (
    <div className={styles.wrapper}>
      {!successfully ? (
        <>
          <div className={styles.drawerRow}>
            <div className={styles.drawerHeader}>
              <h5 className={styles.drawerHeaderTitle}>
                укажите почту,
                <br />
                чтобы получить
                <br />
                исследование
              </h5>
              <div className={styles.drawerHeaderCloseBtnWrapper}>
                <button onClick={closeCTADrawerHandler} className={styles.drawerHeaderCloseBtn}>
                  <CloseBtn />
                </button>
              </div>
            </div>
            <div className={styles.emailInputWrapper}>
              <input
                onBlur={() => setIsDirty(true)}
                value={emailValue}
                onChange={(e) => setEmailValue(e.target.value.trim())}
                className={clsx(styles.emailInput, !isEmailValid && isDirty && styles.onError)}
                placeholder='ваш email'
                type='text'
              />
              <p className={styles.emailInputPrivacy}>
                нажимая на кнопку, я соглашаюсь с&nbsp;условиями
                <br />
                <a
                  className={styles.emailInputPrivacyLink}
                  href='https://antro.cx/agreements'
                  target='_blank'
                  rel='noreferrer'
                >
                  пользовательского соглашения
                </a>
              </p>
            </div>
          </div>
          <div className={styles.drawerRow}>
            <div className={styles.drawerSwitchWrapper}>
              <div className={styles.drawerSwitchResearch}>
                <input
                  className={styles.drawerSwitch}
                  type='checkbox'
                  id='researchSwitch'
                  checked={researchSwitchValue}
                  onChange={() => setResearchSwitchValue((prev) => !prev)}
                />
                <label className={styles.drawerSwitchLabel} htmlFor='researchSwitch'>
                  Toggle
                </label>
              </div>
              <div className={styles.drawerSwitchTextWrapper}>
                <h6 className={styles.drawerSwitchTitle}>хочу поучаствовать в&nbsp;исследовании</h6>
                <p className={styles.drawerSwitchDesc}>пришлём ссылку на&nbsp;небольшой опрос</p>
              </div>
            </div>
          </div>
          <button onClick={onClickHandler} className={styles.submitBtn}>
            получить pdf
          </button>
        </>
      ) : (
        <>
          <div className={styles.drawerHeaderCloseBtnWrapper}>
            <button onClick={closeCTADrawerHandler} className={styles.drawerHeaderCloseBtn}>
              <CloseBtn />
            </button>
          </div>
          <div className={styles.successBody}>
            <h5 className={styles.successTitle}>спасибо</h5>
            <h6 className={styles.successSubTitle}>за заявку!</h6>
            <div className={styles.successDescWrapper}>
              <StarsEmoji className={styles.successDescIcon} />
              <p className={styles.successDesc}>
                мы уже выслали письмо
                <br />
                на указанную почту
              </p>
            </div>
          </div>
          <div className={styles.successFooter}>
            <p className={styles.successFooterDesc}>
              а пока предлагаем перейти на наш сайт,
              <br />
              чтобы познакомится с нами поближе
            </p>
            <a href='https://antro.cx/' target='_blank' rel='noreferrer' className={styles.successFooterLinkBtn}>
              перейти на наш сайт
            </a>
            <button onClick={closeCTADrawerHandler} className={styles.successFooterBackBtn}>
              вернуться на лендинг
            </button>
          </div>
        </>
      )}
    </div>
  )
}
