import React, { FC, useEffect, useState } from 'react'
import styles from './Cases.module.scss'
import { ReactComponent as LockerIcon } from '../../assets/icons/lockerIcon.svg'
import { BudgetChanges } from './components/BudgetChanges'
import { PlanningAndControl } from './components/PlanningAndControl'
import { Priorites } from './components/Priorites'
import { WorkWithContractors } from './components/WorkWithContractors'
import { useInView } from 'react-intersection-observer'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import clsx from 'clsx'
import useWindowSize from '@lib/hooks/useWindowSizeHook'

export const Cases: FC<{ CTADrawerOpenHandler: () => void }> = ({ CTADrawerOpenHandler }) => {
  const [budgetChangesRef, budgetChangesInView] = useInView()
  const [planningAndControlRef, planningAndControlInView] = useInView()
  const [prioritiesRef, prioritiesInView] = useInView()
  const [workWithContractorsRef, workWithContractorsInView] = useInView()
  const [activeComponent, setActiveComponent] = useState<'contractors' | 'priorities' | 'planning' | 'budget' | ''>('')
  const { width } = useWindowSize()

  const SCROLL_OFFSET = 200

  useEffect(() => {
    if (workWithContractorsInView) {
      setActiveComponent('contractors')
    } else if (prioritiesInView) {
      setActiveComponent('priorities')
    } else if (planningAndControlInView) {
      setActiveComponent('planning')
    } else if (budgetChangesInView) {
      setActiveComponent('budget')
    } else {
      setActiveComponent('')
    }
  }, [budgetChangesInView, planningAndControlInView, prioritiesInView, workWithContractorsInView])

  return (
    <div className={styles.wrapper} id='researchPart'>
      {width > 850 && (
        <div className={styles.leftSide}>
          <AnchorLink
            offset={SCROLL_OFFSET}
            className={clsx(styles.anchorLink, activeComponent === 'budget' && styles.active)}
            href='#budgetChanges'
          >
            распределение бюджета
          </AnchorLink>
          <AnchorLink
            offset={SCROLL_OFFSET}
            className={clsx(styles.anchorLink, activeComponent === 'planning' && styles.active)}
            href='#planningAndControl'
          >
            <LockerIcon className={styles.lockerIcon} />
            планирование и контроль
          </AnchorLink>
          <AnchorLink
            offset={SCROLL_OFFSET}
            className={clsx(styles.anchorLink, activeComponent === 'priorities' && styles.active)}
            href='#priorities'
          >
            приоритеты
          </AnchorLink>
          <AnchorLink
            offset={SCROLL_OFFSET}
            className={clsx(styles.anchorLink, activeComponent === 'contractors' && styles.active)}
            href='#workWithContractors'
          >
            <LockerIcon className={styles.lockerIcon} />
            работа с подрядчиками
          </AnchorLink>
          <AnchorLink
            offset={SCROLL_OFFSET}
            className={clsx(styles.anchorLink, activeComponent === 'contractors' && styles.active)}
            href='#workWithContractors'
          >
            <LockerIcon className={styles.lockerIcon} />
            выбор подрядчика
          </AnchorLink>
          <AnchorLink
            offset={SCROLL_OFFSET}
            className={clsx(styles.anchorLink, activeComponent === 'contractors' && styles.active)}
            href='#workWithContractors'
          >
            <LockerIcon className={styles.lockerIcon} />
            отказ от подрядчика
          </AnchorLink>
        </div>
      )}
      <div className={styles.rightSide}>
        <div ref={budgetChangesRef} id='budgetChanges'>
          <BudgetChanges CTADrawerOpenHandler={CTADrawerOpenHandler} />
        </div>
        <div ref={planningAndControlRef} id='planningAndControl'>
          <PlanningAndControl />
        </div>
        <div ref={prioritiesRef} id='priorities'>
          <Priorites CTADrawerOpenHandler={CTADrawerOpenHandler} />
        </div>
        <div ref={workWithContractorsRef} id='workWithContractors'>
          <WorkWithContractors />
        </div>
      </div>
    </div>
  )
}
