import React, { FC } from 'react'
import styles from './MobileMenuContent.module.scss'
import { ReactComponent as CloseBtn } from '../../assets/icons/closeBtnIcon.svg'
import { ReactComponent as AntroLogo } from '../../assets/icons/antroLogo.svg'
import { ReactComponent as LinkArrow } from '../../assets/icons/linkArrow.svg'
import { ReactComponent as TgLogo } from '../../assets/icons/tgIcon.svg'
import { ReactComponent as WhatsUpLogo } from '../../assets/icons/whatsUpIcon.svg'
import { ReactComponent as KeyboardEmoji } from '../../assets/icons/keyboardEmoji.svg'
import { ReactComponent as HandEmoji } from '../../assets/icons/handEmoji.svg'
import AnchorLink from 'react-anchor-link-smooth-scroll'

const SCROLL_OFFSET = 20

export const MobileMenuContent: FC<{ closeMenu: () => void; openCTADrawer: () => void }> = ({
  closeMenu,
  openCTADrawer,
}) => {
  const clickHandler = async () => {
    openCTADrawer()
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.drawerRow}>
        <div className={styles.drawerHeader}>
          <div className={styles.headerWrapper}>
            <div className={styles.logoWrapper}>
              <AntroLogo className={styles.logo} />
            </div>
            <div className={styles.mojo}>ex: Mojo</div>
          </div>
          <div className={styles.drawerHeaderCloseBtnWrapper}>
            <button onClick={closeMenu} className={styles.drawerHeaderCloseBtn}>
              <CloseBtn />
            </button>
          </div>
        </div>
      </div>
      <ul className={styles.navList}>
        <li className={styles.navItem}>
          <AnchorLink offset={SCROLL_OFFSET} className={styles.anchorLink} href='#reason' onClick={closeMenu}>
            <span className={styles.anchorLinkText}>
              причины
              <LinkArrow className={styles.anchorLinkArrow} />
            </span>
          </AnchorLink>
        </li>
        <li className={styles.navItem}>
          <AnchorLink offset={SCROLL_OFFSET + 50} className={styles.anchorLink} href='#whoBenefits' onClick={closeMenu}>
            <span className={styles.anchorLinkText}> кому&nbsp;будет </span>
            <span className={styles.anchorLinkText}>
              полезно <LinkArrow className={styles.anchorLinkArrow} />
            </span>
          </AnchorLink>
        </li>
        <li className={styles.navItem}>
          <AnchorLink offset={SCROLL_OFFSET} className={styles.anchorLink} href='#researchPart' onClick={closeMenu}>
            <span className={styles.anchorLinkText}>часть</span>
            <span className={styles.anchorLinkText}>
              исследования <LinkArrow className={styles.anchorLinkArrow} />
            </span>
          </AnchorLink>
        </li>
        <li className={styles.navItem}>
          <AnchorLink offset={SCROLL_OFFSET} className={styles.anchorLink} href='#about' onClick={closeMenu}>
            <span className={styles.anchorLinkText}> о нас</span>
            <LinkArrow className={styles.anchorLinkArrow} />
          </AnchorLink>
        </li>
      </ul>
      <div>
        <address className={styles.contactsWrapper}>
          <div className={styles.contactsMobileWrapper}>
            <a className={styles.link} href='tel:+79994530020' target='_blank' rel='noreferrer'>
              <HandEmoji className={styles.linkIcon} />
              <span className={styles.linkText}>+7 999 453 00 20</span>
            </a>
            <a className={styles.link} href='https://wa.me/79136599359' target='_blank' rel='noreferrer'>
              <WhatsUpLogo className={styles.linkIcon} />
            </a>
          </div>
          <div className={styles.contactsMobileWrapper}>
            <a className={styles.link} href='mailto:hello@antro.cx' target='_blank' rel='noreferrer'>
              <KeyboardEmoji className={styles.linkIcon} />
              <span className={styles.linkText}>hello@antro.cx</span>
            </a>
            <a className={styles.link} href='https://t.me/antrocx' target='_blank' rel='noreferrer'>
              <TgLogo className={styles.linkIcon} />
            </a>
          </div>
        </address>
        <button onClick={clickHandler} className={styles.submitBtn}>
          получить pdf
        </button>
      </div>
    </div>
  )
}
