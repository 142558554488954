import React, { useEffect, useRef, useState } from 'react'

export const WeResearchBackground = ({ anim, className }) => {
  const [elColor, setElColor] = useState(['#474458', '#474458', '#474458', '#474458'])

  const interval = useRef(null)

  const getRandomNumber = (min, max) => {
    return Math.round(Math.random() * (max - min) + min)
  }

  const changeColorsHandler = () => {
    const randomItem = getRandomNumber(0, elColor.length - 1)
    setElColor(() => {
      const newArr = ['#474458', '#474458', '#474458', '#474458']
      newArr[randomItem] = '#3480F1'
      return newArr
    })
  }

  useEffect(() => {
    if (anim) {
      interval.current = setInterval(changeColorsHandler, 1500)
    } else {
      clearInterval(interval.current)
    }

    return () => {
      clearInterval(interval.current)
    }
  }, [anim])

  return (
    <svg
      className={className}
      width='696'
      height='260'
      viewBox='0 0 696 260'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g filter='url(#filter0_b_218_1561)'>
        <circle cx='84.3592' cy='84.3592' r='84.3592' fill='white' fillOpacity='0.09' />
      </g>
      <rect x='46' y='108' width='28' height='4' rx='2' fill={elColor[0]} style={{ transition: 'fill .5s ease-in' }} />
      <g filter='url(#filter1_b_218_1561)'>
        <rect x='46.1172' y='56' width='75.3609' height='17.9966' rx='8.99831' fill='white' fillOpacity='0.09' />
      </g>
      <g filter='url(#filter2_b_218_1561)'>
        <rect x='46.1172' y='87.4961' width='52.8651' height='10.1231' rx='5.06155' fill='white' fillOpacity='0.09' />
      </g>
      <g filter='url(#filter3_b_218_1561)'>
        <circle cx='562.239' cy='56.2395' r='56.2395' fill='white' fillOpacity='0.09' />
      </g>
      <rect x='535' y='74' width='28' height='4' rx='2' fill={elColor[1]} style={{ transition: 'fill .5s ease-in' }} />
      <g filter='url(#filter4_b_218_1561)'>
        <rect x='535.242' y='34' width='55.1147' height='13.1617' rx='6.58086' fill='white' fillOpacity='0.09' />
      </g>
      <g filter='url(#filter5_b_218_1561)'>
        <rect x='535.242' y='57.0352' width='38.6625' height='7.40346' rx='3.70173' fill='white' fillOpacity='0.09' />
      </g>
      <g filter='url(#filter6_b_218_1561)'>
        <circle cx='628.64' cy='196.359' r='84.3592' fill='white' fillOpacity='0.09' />
      </g>
      <rect x='590' y='226' width='28' height='4' rx='2' fill={elColor[2]} style={{ transition: 'fill .5s ease-in' }} />
      <g filter='url(#filter7_b_218_1561)'>
        <rect x='590.398' y='173.863' width='75.3609' height='17.9966' rx='8.99831' fill='white' fillOpacity='0.09' />
      </g>
      <g filter='url(#filter8_b_218_1561)'>
        <rect x='590.398' y='205.359' width='52.8651' height='10.1231' rx='5.06155' fill='white' fillOpacity='0.09' />
      </g>
      <g filter='url(#filter9_b_218_1561)'>
        <circle cx='348.5' cy='175.5' r='175.5' fill='white' fillOpacity='0.09' />
      </g>
      <g filter='url(#filter10_b_218_1561)'>
        <rect x='264' y='151' width='170' height='19' rx='9.5' fill='white' fillOpacity='0.09' />
      </g>
      <g filter='url(#filter11_b_218_1561)'>
        <rect x='264' y='184' width='120' height='10' rx='5' fill='white' fillOpacity='0.09' />
      </g>
      <rect x='265' y='220' width='28' height='4' rx='2' fill={elColor[3]} style={{ transition: 'fill .5s ease-in' }} />
      <defs>
        <filter
          id='filter0_b_218_1561'
          x='-28.1197'
          y='-28.1197'
          width='224.958'
          height='224.958'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feGaussianBlur in='BackgroundImageFix' stdDeviation='14.0599' />
          <feComposite in2='SourceAlpha' operator='in' result='effect1_backgroundBlur_218_1561' />
          <feBlend mode='normal' in='SourceGraphic' in2='effect1_backgroundBlur_218_1561' result='shape' />
        </filter>
        <filter
          id='filter1_b_218_1561'
          x='17.9975'
          y='27.8803'
          width='131.599'
          height='74.2356'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feGaussianBlur in='BackgroundImageFix' stdDeviation='14.0599' />
          <feComposite in2='SourceAlpha' operator='in' result='effect1_backgroundBlur_218_1561' />
          <feBlend mode='normal' in='SourceGraphic' in2='effect1_backgroundBlur_218_1561' result='shape' />
        </filter>
        <filter
          id='filter2_b_218_1561'
          x='17.9975'
          y='59.3764'
          width='109.107'
          height='66.3645'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feGaussianBlur in='BackgroundImageFix' stdDeviation='14.0599' />
          <feComposite in2='SourceAlpha' operator='in' result='effect1_backgroundBlur_218_1561' />
          <feBlend mode='normal' in='SourceGraphic' in2='effect1_backgroundBlur_218_1561' result='shape' />
        </filter>
        <filter
          id='filter3_b_218_1561'
          x='477.88'
          y='-28.1197'
          width='168.716'
          height='168.72'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feGaussianBlur in='BackgroundImageFix' stdDeviation='14.0599' />
          <feComposite in2='SourceAlpha' operator='in' result='effect1_backgroundBlur_218_1561' />
          <feBlend mode='normal' in='SourceGraphic' in2='effect1_backgroundBlur_218_1561' result='shape' />
        </filter>
        <filter
          id='filter4_b_218_1561'
          x='507.122'
          y='5.88027'
          width='111.357'
          height='69.3996'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feGaussianBlur in='BackgroundImageFix' stdDeviation='14.0599' />
          <feComposite in2='SourceAlpha' operator='in' result='effect1_backgroundBlur_218_1561' />
          <feBlend mode='normal' in='SourceGraphic' in2='effect1_backgroundBlur_218_1561' result='shape' />
        </filter>
        <filter
          id='filter5_b_218_1561'
          x='507.122'
          y='28.9154'
          width='94.9035'
          height='63.6418'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feGaussianBlur in='BackgroundImageFix' stdDeviation='14.0599' />
          <feComposite in2='SourceAlpha' operator='in' result='effect1_backgroundBlur_218_1561' />
          <feBlend mode='normal' in='SourceGraphic' in2='effect1_backgroundBlur_218_1561' result='shape' />
        </filter>
        <filter
          id='filter6_b_218_1561'
          x='516.162'
          y='83.8803'
          width='224.958'
          height='224.958'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feGaussianBlur in='BackgroundImageFix' stdDeviation='14.0599' />
          <feComposite in2='SourceAlpha' operator='in' result='effect1_backgroundBlur_218_1561' />
          <feBlend mode='normal' in='SourceGraphic' in2='effect1_backgroundBlur_218_1561' result='shape' />
        </filter>
        <filter
          id='filter7_b_218_1561'
          x='562.279'
          y='145.744'
          width='131.599'
          height='74.2356'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feGaussianBlur in='BackgroundImageFix' stdDeviation='14.0599' />
          <feComposite in2='SourceAlpha' operator='in' result='effect1_backgroundBlur_218_1561' />
          <feBlend mode='normal' in='SourceGraphic' in2='effect1_backgroundBlur_218_1561' result='shape' />
        </filter>
        <filter
          id='filter8_b_218_1561'
          x='562.279'
          y='177.24'
          width='109.107'
          height='66.3645'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feGaussianBlur in='BackgroundImageFix' stdDeviation='14.0599' />
          <feComposite in2='SourceAlpha' operator='in' result='effect1_backgroundBlur_218_1561' />
          <feBlend mode='normal' in='SourceGraphic' in2='effect1_backgroundBlur_218_1561' result='shape' />
        </filter>
        <filter
          id='filter9_b_218_1561'
          x='144.88'
          y='-28.1197'
          width='407.239'
          height='407.239'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feGaussianBlur in='BackgroundImageFix' stdDeviation='14.0599' />
          <feComposite in2='SourceAlpha' operator='in' result='effect1_backgroundBlur_218_1561' />
          <feBlend mode='normal' in='SourceGraphic' in2='effect1_backgroundBlur_218_1561' result='shape' />
        </filter>
        <filter
          id='filter10_b_218_1561'
          x='235.88'
          y='122.88'
          width='226.239'
          height='75.2395'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feGaussianBlur in='BackgroundImageFix' stdDeviation='14.0599' />
          <feComposite in2='SourceAlpha' operator='in' result='effect1_backgroundBlur_218_1561' />
          <feBlend mode='normal' in='SourceGraphic' in2='effect1_backgroundBlur_218_1561' result='shape' />
        </filter>
        <filter
          id='filter11_b_218_1561'
          x='235.88'
          y='155.88'
          width='176.239'
          height='66.2395'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feGaussianBlur in='BackgroundImageFix' stdDeviation='14.0599' />
          <feComposite in2='SourceAlpha' operator='in' result='effect1_backgroundBlur_218_1561' />
          <feBlend mode='normal' in='SourceGraphic' in2='effect1_backgroundBlur_218_1561' result='shape' />
        </filter>
      </defs>
    </svg>
  )
}
