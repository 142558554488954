import React, { FC, useEffect, useState } from 'react'
import styles from './MainBlock.module.scss'
import { ReactComponent as CashEmoji } from '../../assets/icons/cashEmoji.svg'
import { ReactComponent as WorkerEmoji } from '../../assets/icons/workerEmoji.svg'
import { ReactComponent as TornadoEmoji } from '../../assets/icons/tornadoEmoji.svg'
import { useInView } from 'react-intersection-observer'
import clsx from 'clsx'

export const MainBlock: FC = () => {
  const [ref, inView, entry] = useInView()
  const [isAnimActivated, setIsAnimActivated] = useState(false)

  useEffect(() => {
    if (inView && !isAnimActivated) {
      setIsAnimActivated(true)
    }
  }, [inView])

  return (
    <div ref={ref} className={styles.wrapper}>
      <h1 className={styles.title}>
        <span className={clsx(styles.titleResearch, isAnimActivated && styles.animActivated)}>исследование</span>
        <span className={clsx(styles.howResponse, isAnimActivated && styles.animActivated)}>как отвечает</span>
        <span className={clsx(styles.eCommerce, isAnimActivated && styles.animActivated)}>eCommerce</span>
        <span className={clsx(styles.year, isAnimActivated && styles.animActivated)}>на 2022 год?</span>
      </h1>
      <div className={clsx(styles.popupReduce, isAnimActivated && styles.animActivated)}>
        <CashEmoji className={styles.popupIcon} /> <span className={styles.popupText}>сокращение бюджета</span>
      </div>
      <div className={clsx(styles.popupContractors, isAnimActivated && styles.animActivated)}>
        <WorkerEmoji className={styles.popupIcon} /> <span className={styles.popupText}>работа с подрядчиками</span>
      </div>
      <div className={clsx(styles.popupPriority, isAnimActivated && styles.animActivated)}>
        <TornadoEmoji className={styles.popupIcon} /> <span className={styles.popupText}>смена приоритетов</span>
      </div>
    </div>
  )
}
