import gsap from 'gsap'
import React, { FC, useEffect, useRef } from 'react'

import styles from './CursorAura.module.scss'

export const CursorAura: FC = () => {
  const follower = useRef(null)
  const follower1 = useRef(null)

  const getMaxY = (e, follower) => {
    const bodyHeight = document.body.scrollHeight
    const cursorYPosition = e.pageY
    const elHeight = follower?.current?.getBoundingClientRect()?.height
    if (cursorYPosition + elHeight / 2 > bodyHeight) {
      return bodyHeight - elHeight / 2
    } else {
      return cursorYPosition
    }
  }

  const mouseMove = (e) => {
    const x = e.pageX

    const y1 = getMaxY(e, follower)
    const y2 = getMaxY(e, follower1)
    gsap.to(follower?.current, {
      repeat: 0,
      duration: 0.4,
      css: {
        left: x,
        top: y1,
        visibility: 'visible',
      },
    })
    gsap.to(follower1?.current, {
      repeat: 0,
      duration: 0.6,
      css: {
        left: x,
        top: y2,
        visibility: 'visible',
      },
    })
  }
  useEffect(() => {
    window.addEventListener('mousemove', mouseMove)
    return () => window.removeEventListener('mousemove', mouseMove)
  }, [])

  return (
    <>
      <div ref={follower} className={styles.aura} />
      <div ref={follower1} className={styles.aura2} />
    </>
  )
}
