import React, { FC } from 'react'
import styles from './Footer.module.scss'
import clsx from 'clsx'
import { ReactComponent as AntroLogo } from '../../assets/icons/antroLogo.svg'
import { ReactComponent as TgLogo } from '../../assets/icons/tgIcon.svg'
import { ReactComponent as WhatsUpLogo } from '../../assets/icons/whatsUpIcon.svg'
import { ReactComponent as KeyboardEmoji } from '../../assets/icons/keyboardEmoji.svg'
import { ReactComponent as HandEmoji } from '../../assets/icons/handEmoji.svg'
import useWindowSize from '@lib/hooks/useWindowSizeHook'

export const Footer: FC = () => {
  const { width } = useWindowSize()
  return (
    <div className={styles.wrapper}>
      <div className={styles.row}>
        <div className={styles.vcCard}>
          <h5 className={styles.cardTitle}>блог на vc.ru</h5>
          <div className={styles.vcCardSubscribers}>1000+ подписчиков</div>
          <p className={styles.cardDesc}>
            делимся лучшими практиками,
            <br />
            показываем кейсы и помогаем
            <br />
            развивать сайты и приложения
          </p>
          <a className={styles.cardLink} href='https://vc.ru/antro' target='_blank' rel='noreferrer'>
            читать
          </a>
        </div>
        <div className={styles.tgCard}>
          <h5 className={styles.cardTitle}>
            тг-канал
            <br />
            «опытное агентство»
          </h5>
          <p className={styles.cardDesc}>
            наш гендир рассказывает,
            <br />
            как поднять LTV с колен, показывает
            <br />
            красивые картинки и постит мемы
          </p>
          <a className={styles.cardLink} href='https://t.me/antro_cx' target='_blank' rel='noreferrer'>
            читать
          </a>
        </div>
      </div>
      <div className={styles.aboutCard}>
        <div className={styles.aboutCardRow}>
          <h5 className={styles.aboutCardTitle}>
            проводим исследования,
            {width > 700 && <br />}
            создаем и&nbsp;развиваем веб-продукты,
            {width > 700 && <br />}
            продвигаем опытоцентричность
          </h5>
          <AntroLogo className={styles.aboutCardLogo} />
        </div>
        <div className={styles.aboutCardSecondRow}>
          {width > 700 ? (
            <address className={styles.contactsWrapper}>
              <a className={styles.link} href='tel:+79994530020' target='_blank' rel='noreferrer'>
                <HandEmoji className={styles.linkIcon} />
                <span className={styles.linkText}>+7 999 453 00 20</span>
              </a>
              <a className={styles.link} href='mailto:hello@antro.cx' target='_blank' rel='noreferrer'>
                <KeyboardEmoji className={styles.linkIcon} />
                <span className={styles.linkText}>hello@antro.cx</span>
              </a>
              <a className={styles.link} href='https://t.me/antrocx' target='_blank' rel='noreferrer'>
                <TgLogo className={styles.linkIcon} />
              </a>
              <a className={styles.link} href='https://wa.me/79136599359' target='_blank' rel='noreferrer'>
                <WhatsUpLogo className={styles.linkIcon} />
              </a>
            </address>
          ) : (
            <address className={styles.contactsWrapper}>
              <div className={styles.contactsMobileWrapper}>
                <a className={styles.link} href='tel:+79994530020' target='_blank' rel='noreferrer'>
                  <HandEmoji className={styles.linkIcon} />
                  <span className={styles.linkText}>+7 999 453 00 20</span>
                </a>
                <a className={styles.link} href='https://wa.me/79136599359' target='_blank' rel='noreferrer'>
                  <WhatsUpLogo className={styles.linkIcon} />
                </a>
              </div>
              <div className={styles.contactsMobileWrapper}>
                <a className={styles.link} href='mailto:hello@antro.cx' target='_blank' rel='noreferrer'>
                  <KeyboardEmoji className={styles.linkIcon} />
                  <span className={styles.linkText}>hello@antro.cx</span>
                </a>
                <a className={styles.link} href='https://t.me/antrocx' target='_blank' rel='noreferrer'>
                  <TgLogo className={styles.linkIcon} />
                </a>
              </div>
            </address>
          )}
          <a
            className={styles.landingLink}
            href='https://antro.cx/?talk_about_open_step1=true'
            target='_blank'
            rel='noreferrer'
          >
            обсудить проект
          </a>
        </div>
      </div>
      <div className={styles.deepFooter}>
        <div className={styles.deepFooterRow}>
          <p className={styles.inn}>ООО «Антро», ИНН 5501199587</p>
        </div>
        <div className={styles.deepFooterRow}>
          <div className={styles.privacyLinks}>
            <a className={styles.privacyLink} href='https://www.gosuslugi.ru/itorgs' target='_blank' rel='noreferrer'>
              аккредитованная ИТ-компания
            </a>
            <a className={styles.privacyLink} href='https://antro.cx/agreements' target='_blank' rel='noreferrer'>
              пользовательское соглашение
            </a>
          </div>
          <div className={styles.copyright}>© antro, 2019–2022</div>
        </div>
      </div>
    </div>
  )
}
