import React, { FC, useEffect, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import styles from './WeResearch.module.scss'
import { WeResearchBackground } from './background/WeResearchBackground'

export const WeResearch: FC = () => {
  const [ref, inView] = useInView()
  const [isAnimActivated, setIsAnimActivated] = useState(false)

  useEffect(() => {
    if (inView && !isAnimActivated) {
      setIsAnimActivated(true)
    } else {
      setIsAnimActivated(false)
    }
  }, [inView])

  return (
    <div ref={ref} className={styles.wrapper}>
      <WeResearchBackground anim={isAnimActivated} className={styles.background} />
      <h5 className={styles.title}>
        за последние
        <br />
        2 месяца
        <br />
        <span className={styles.blue}>мы исследовали</span>
      </h5>
      <p className={styles.desc}>
        распределение бюджета,
        <br />
        изменения приоритетов, выбор
        <br />
        подрядчиков и требования к ним
      </p>
    </div>
  )
}
