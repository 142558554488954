import React, { FC } from 'react'
import styles from './Excerption.module.scss'
import { ReactComponent as Quote } from '../../assets/icons/quote.svg'

interface ExcerptionProps {
  author: {
    name: string
    position: string
  }
}

export const Excerption: FC<ExcerptionProps> = ({ children, author }) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.textWrapper}>
        <span className={styles.leftQuote}>
          <Quote />
        </span>
        {children}
        <span className={styles.rightQuote}>
          <Quote />
        </span>
      </div>
      <div className={styles.authorWrapper}>
        <div className={styles.authorNameWrapper}>
          <h5 className={styles.authorName}>{author.name}</h5>
          <p className={styles.authorPosition}>{author.position}</p>
        </div>
      </div>
    </div>
  )
}
