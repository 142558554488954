import { api } from '@app/api'
import useWindowSize from '@lib/hooks/useWindowSizeHook'
import React, { FC, useEffect, useState } from 'react'
import styles from './SubscribeComponent.module.scss'
import clsx from 'clsx'
import { validateEmail } from '@lib/helpers/validateEmail'

interface DataInterface {
  url: string
  mail: string
  // eslint-disable-next-line camelcase
  send_pdf: boolean
  research?: boolean
  presentation?: boolean
  // eslint-disable-next-line camelcase
  signed_in?: boolean
}

export const SubscribeComponent: FC<{
  btnTitle: string
  afterSendHandler: () => void
  isResearch?: boolean
  isPresentation?: boolean
  isSignedIn?: boolean
}> = ({ btnTitle, afterSendHandler, isResearch = false, isPresentation = false, isSignedIn = false }) => {
  const { width } = useWindowSize()
  const [email, setEmail] = useState('')
  const [isEmailValid, setIsEmailValid] = useState(false)
  const [isDirty, setIsDirty] = useState(false)

  const sendEmailHandler = async () => {
    if (validateEmail(email)) {
      const url = window.location.href

      const data: DataInterface = {
        url,
        mail: email,
        send_pdf: true,
      }
      if (isResearch) {
        data.research = true
      }
      if (isSignedIn) {
        data.signed_in = true
      }
      const response = await api.post<any>(`subscribingemail/`, data)
      afterSendHandler()
    } else {
      setIsDirty(true)
      setIsEmailValid(false)
    }
  }

  useEffect(() => {
    if (validateEmail(email) && isDirty) {
      setIsEmailValid(true)
    } else {
      setIsEmailValid(false)
    }
  }, [email, isDirty])

  return (
    <div className={styles.wrapper}>
      <div className={styles.inputWrapper}>
        <input
          onBlur={() => setIsDirty(true)}
          className={clsx(styles.input, !isEmailValid && isDirty && styles.onError)}
          type='text'
          placeholder='ваш email'
          value={email}
          onChange={(e) => setEmail(e.target.value.trim())}
        />
        {width <= 850 && (
          <p className={styles.privacyText}>
            нажимая&nbsp;на&nbsp;кнопку,&nbsp;я&nbsp;соглашаюсь&nbsp;с&nbsp;условиями{' '}
            <a className={styles.privacyLink} href='https://antro.cx/agreements' target='_blank' rel='noreferrer'>
              пользовательского&nbsp;соглашения
            </a>
          </p>
        )}
        <button onClick={sendEmailHandler} className={styles.subscribeBtn}>
          {btnTitle}
        </button>
      </div>
      {width > 850 && (
        <p className={styles.privacyText}>
          нажимая на кнопку, я соглашаюсь с условиями{' '}
          <a className={styles.privacyLink} href='https://antro.cx/agreements' target='_blank' rel='noreferrer'>
            пользовательского соглашения
          </a>
        </p>
      )}
    </div>
  )
}
