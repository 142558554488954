import React from 'react'
import ReactDOM from 'react-dom'

import './index.css'

import App from './components/App'

document.documentElement.classList.add('mts-theme', 'mts-theme-light')

ReactDOM.render(<App />, document.getElementById('root'))
