import React, { FC } from 'react'
import styles from './BottomMenu.module.scss'
import clsx from 'clsx'

export const BottomMenu: FC<{
  isScrollingStyle: boolean
  CTADrawerOpenHandler: () => void
  menuOpenHandler: () => void
}> = ({ isScrollingStyle, CTADrawerOpenHandler, menuOpenHandler }) => {
  return (
    <div className={clsx(styles.wrapper, isScrollingStyle && styles.onScroll)}>
      <button className={styles.getPdfBtn} onClick={CTADrawerOpenHandler}>
        получить pdf
      </button>
      <button className={styles.menuBtn} onClick={menuOpenHandler}>
        <span className={styles.menuBtnText}>меню</span>
        <div className={styles.burger}>
          <span />
          <span />
        </div>
      </button>
    </div>
  )
}
