import React, { useEffect, useRef, useState } from 'react'
import { Cases } from '@components/Cases'
import { Excerption } from '@components/Excerption'
import { Footer } from '@components/Footer'
import { Header } from '@components/Header'
import { HelpToBusiness } from '@components/HelpToBusiness'
import { MainBlock } from '@components/MainBlock'
import { Numbers } from '@components/Numbers'
import { WhatDifference } from '@components/WhatDifference'
import { WhatHappened } from '@components/WhatHappened'
import { WhoBenefits } from '@components/WhoBenefits'
import { YouMayImproveResearch } from '@components/YouMayImproveResearch'
import styles from './style.module.scss'
import { useInView } from 'react-intersection-observer'
import clsx from 'clsx'
import { Strategy } from '@components/Strategy'
import { CursorAura } from '@components/CursorAura'
import Drawer from 'antd/es/drawer'
import { DrawerContent } from '@components/DrawerContent'
import useWindowSize from '@lib/hooks/useWindowSizeHook'
import { BottomMenu } from '@components/BottomMenu'
import { getParameterByName } from '@lib/helpers/getParametersByName'
import { api } from '@app/api'
import { MobileMenuContent } from '@components/MobileMenuContent'

const App = () => {
  const [firstExRef, firstExInView] = useInView({ threshold: 0.5 })
  const [secondExRef, secondExInView] = useInView({ threshold: 0.5 })
  const [thirdExRef, thirdExInView] = useInView({ threshold: 0.5 })
  const [isFirstExAnimActivated, setIsFirstExAnimActivated] = useState(false)
  const [isSecondExAnimActivated, setIsSecondFirstExAnimActivated] = useState(false)
  const [isThirdExAnimActivated, setIsThirdFirstExAnimActivated] = useState(false)
  const [isScrollingStyle, setIsScrollingStyle] = useState(false)
  const [isCTADrawerOpen, setIsCTADrawerOpen] = useState(false)
  const [isMenuOpened, setIsMenuOpened] = useState(false)

  const { width } = useWindowSize()

  const scrollHandler = (e) => {
    if (window.scrollY > 30 && !isScrollingStyle) {
      setIsScrollingStyle(true)
    } else if (window.scrollY <= 30) {
      setIsScrollingStyle(false)
    }
  }

  const CTADrawerOpenHandler = () => {
    setIsCTADrawerOpen(true)
  }

  const menuOpenHandler = () => {
    setIsMenuOpened(true)
  }

  useEffect(() => {
    window.addEventListener('scroll', scrollHandler)

    return () => {
      window.removeEventListener('scroll', scrollHandler)
    }
  }, [])

  useEffect(() => {
    if (firstExInView && !isFirstExAnimActivated) {
      setIsFirstExAnimActivated(true)
    }
  }, [firstExInView])

  useEffect(() => {
    if (secondExInView && !isSecondExAnimActivated) {
      setIsSecondFirstExAnimActivated(true)
    }
  }, [secondExInView])

  useEffect(() => {
    if (thirdExInView && !isThirdExAnimActivated) {
      setIsThirdFirstExAnimActivated(true)
    }
  }, [thirdExInView])

  return (
    <React.StrictMode>
      <Drawer
        width='auto'
        height='100%'
        maskStyle={{ background: 'rgba(10, 6, 32, 0.3)', backdropFilter: 'blur(15px)' }}
        style={{ background: '#0B0721', overflow: 'auto' }}
        headerStyle={{ display: 'none' }}
        className={styles.drawer}
        open={isCTADrawerOpen}
        onClose={() => setIsCTADrawerOpen(false)}
        placement={width <= 1050 ? 'bottom' : 'right'}
      >
        <DrawerContent closeCTADrawerHandler={() => setIsCTADrawerOpen(false)} />
      </Drawer>
      <Drawer
        width='auto'
        height='100%'
        maskStyle={{ background: 'rgba(10, 6, 32, 0.3)', backdropFilter: 'blur(15px)' }}
        style={{ background: '#0B0721', overflow: 'auto' }}
        headerStyle={{ display: 'none' }}
        className={styles.drawer}
        open={isMenuOpened}
        onClose={() => setIsMenuOpened(false)}
        placement='bottom'
      >
        <MobileMenuContent openCTADrawer={CTADrawerOpenHandler} closeMenu={() => setIsMenuOpened(false)} />
      </Drawer>
      <div className={styles.appContainer}>
        {width > 800 && <CursorAura />}
        <div className={styles.pageWrapper}>
          <Header CTADrawerOpenHandler={CTADrawerOpenHandler} isScrollingStyle={isScrollingStyle && width > 1050} />
          {width <= 1050 && (
            <BottomMenu
              menuOpenHandler={menuOpenHandler}
              CTADrawerOpenHandler={CTADrawerOpenHandler}
              isScrollingStyle={isScrollingStyle && width <= 1050}
            />
          )}
          <MainBlock />
          <WhatDifference />
          <Numbers CTADrawerOpenHandler={CTADrawerOpenHandler} />
          <div ref={firstExRef} className={clsx(styles.excerption, isFirstExAnimActivated && styles.animActivated)}>
            <Excerption
              author={{
                name: 'Александр Соколов',
                position: 'Руководитель группы проектов\neCommerce в ДжамильКо',
              }}
            >
              <h5 className={styles.excerptionText}>
                <span className={styles.normal}>Каждую</span> <span className={styles.transparent}>несущественную</span>{' '}
                <span className={styles.normal}>задачу</span>{' '}
                <span className={styles.transparent}>а-ля «переставить кнопку в&nbsp;другое место», </span>
                <span className={styles.normal}>разбираем </span>
                <span className={styles.transparent}>даже если это не&nbsp;очень трудозатратно</span>
              </h5>
            </Excerption>
          </div>
          <WhatHappened />
          <WhoBenefits />
          <Cases CTADrawerOpenHandler={CTADrawerOpenHandler} />
          <YouMayImproveResearch />
          <HelpToBusiness />
          <div ref={secondExRef} className={clsx(styles.excerption, isSecondExAnimActivated && styles.animActivated)}>
            <Excerption
              author={{
                name: 'Галина Резванцева',
                position: 'старший менеджер проектов\ne-commerce ДжамильКо',
              }}
            >
              <h5 className={styles.excerptionText}>
                <span className={styles.transparent}>Я&nbsp;работала с&nbsp;десятками подрядчиков, но&nbsp;</span>
                <span className={styles.normal}>впервые встречаю такое качество.</span>{' '}
                <span className={styles.transparent}>Команда Antro </span>
                <span className={styles.normal}>спроектировала корзину New Balance в&nbsp;сжатые сроки</span>,{' '}
                <span className={styles.transparent}>
                  хотя у&nbsp;нас сложные бизнес-процессы, требующие глубокого погружения. Корзину ещё не внедрили,
                  но&nbsp;уже понятно, что
                </span>{' '}
                <span className={styles.normal}>она решит бизнес-задачу на&nbsp;100%</span>
              </h5>
            </Excerption>
          </div>
          <Strategy />
          <div ref={thirdExRef} className={clsx(styles.excerption, isThirdExAnimActivated && styles.animActivated)}>
            <Excerption
              author={{
                name: 'Максим Десятых',
                position: 'основатель red_mad_robot',
              }}
            >
              <h5 className={styles.excerptionText}>
                <span className={styles.transparent}>Рынок ведет себя, </span>
                <span className={styles.normal}>как в&nbsp;обычные кризисы&nbsp;&mdash; </span>
                <span className={styles.transparent}>
                  сначала резкий обвал, дефицит заказов, отмены и&nbsp;дебиторские задолженности, а&nbsp;потом
                  постепенное восстановление и&nbsp;стабилизация. Вот
                </span>{' '}
                <span className={styles.normal}>сейчас период стабилизации</span>.{' '}
                <span className={styles.transparent}>Нужно </span>
                <span className={styles.normal}>усердно работать, чтобы хоть немного развиваться</span>
              </h5>
            </Excerption>
          </div>
          <Footer />
        </div>
      </div>
    </React.StrictMode>
  )
}

export default App
