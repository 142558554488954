import React, { useEffect, useRef, useState } from 'react'

export const CrisisBackground = ({ anim, className }) => {
  const [elColor, setElColor] = useState(['#474458', '#474458', '#474458', '#474458'])

  const interval = useRef(null)

  const getRandomNumber = (min, max) => {
    return Math.round(Math.random() * (max - min) + min)
  }

  const changeColorsHandler = () => {
    const randomItem = getRandomNumber(0, elColor.length - 1)
    setElColor(() => {
      const newArr = ['#474458', '#474458', '#474458', '#474458']
      newArr[randomItem] = '#E954C3'
      return newArr
    })
  }

  useEffect(() => {
    if (anim) {
      interval.current = setInterval(changeColorsHandler, 1500)
    } else {
      clearInterval(interval.current)
    }

    return () => {
      clearInterval(interval.current)
    }
  }, [anim])

  return (
    <svg
      className={className}
      width='650'
      height='274'
      viewBox='0 0 650 274'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g filter='url(#filter0_f_218_1517)'>
        <rect x='512.5' y='14' width='482.497' height='368.221' rx='50.7891' fill='white' fillOpacity='0.09' />
        <g filter='url(#filter1_b_218_1517)'>
          <circle cx='583.603' cy='94.1104' r='31.7432' fill='white' fillOpacity='0.09' />
        </g>
        <rect x='630.586' y='76.1113' width='77' height='17' rx='8.5' fill='white' fillOpacity='0.09' />
        <path
          d='M631.411 104.597C631.041 103.93 631.523 103.111 632.285 103.111H638.886C639.649 103.111 640.131 103.93 639.761 104.597L636.46 110.538C636.079 111.224 635.093 111.224 634.712 110.538L631.411 104.597Z'
          fill='#E954C3'
        />
        <rect x='646.586' y='103.111' width='48' height='5' rx='2.5' fill='white' fillOpacity='0.09' />
        <g filter='url(#filter2_b_218_1517)'>
          <circle cx='583.603' cy='198.11' r='31.7432' fill='white' fillOpacity='0.09' />
        </g>
        <rect x='630.586' y='180.111' width='77' height='17' rx='8.5' fill='white' fillOpacity='0.09' />
        <path
          d='M631.411 208.597C631.041 207.93 631.523 207.111 632.285 207.111H638.886C639.649 207.111 640.131 207.93 639.761 208.597L636.46 214.538C636.079 215.224 635.093 215.224 634.712 214.538L631.411 208.597Z'
          fill='#E954C3'
        />
        <rect x='646.586' y='207.111' width='48' height='5' rx='2.5' fill='white' fillOpacity='0.09' />
      </g>
      <g filter='url(#filter3_b_218_1517)'>
        <rect y='14' width='482.497' height='368.221' rx='50.7891' fill='white' fillOpacity='0.09' />
        <g filter='url(#filter4_b_218_1517)'>
          <circle cx='71.1026' cy='94.1104' r='31.7432' fill='white' fillOpacity='0.09' />
        </g>
        <rect x='118.086' y='76.1113' width='77' height='17' rx='8.5' fill='white' fillOpacity='0.09' />
        <path
          d='M118.911 104.597C118.541 103.93 119.023 103.111 119.785 103.111H126.386C127.149 103.111 127.631 103.93 127.261 104.597L123.96 110.538C123.579 111.224 122.593 111.224 122.212 110.538L118.911 104.597Z'
          fill={elColor[0]}
          style={{ transition: 'fill .5s ease-in' }}
        />
        <rect x='134.086' y='103.111' width='48' height='5' rx='2.5' fill='white' fillOpacity='0.09' />
        <g filter='url(#filter5_b_218_1517)'>
          <circle cx='71.1026' cy='198.11' r='31.7432' fill='white' fillOpacity='0.09' />
        </g>
        <rect x='118.086' y='180.111' width='77' height='17' rx='8.5' fill='white' fillOpacity='0.09' />
        <path
          d='M118.911 208.597C118.541 207.93 119.023 207.111 119.785 207.111H126.386C127.149 207.111 127.631 207.93 127.261 208.597L123.96 214.538C123.579 215.224 122.593 215.224 122.212 214.538L118.911 208.597Z'
          fill={elColor[2]}
          style={{ transition: 'fill .5s ease-in' }}
        />
        <rect x='134.086' y='207.111' width='48' height='5' rx='2.5' fill='white' fillOpacity='0.09' />
        <g filter='url(#filter6_b_218_1517)'>
          <circle cx='289.103' cy='94.1104' r='31.7432' fill='white' fillOpacity='0.09' />
        </g>
        <rect x='336.086' y='76.1113' width='77' height='17' rx='8.5' fill='white' fillOpacity='0.09' />
        <path
          d='M336.911 104.597C336.541 103.93 337.023 103.111 337.785 103.111H344.386C345.149 103.111 345.631 103.93 345.261 104.597L341.96 110.538C341.579 111.224 340.593 111.224 340.212 110.538L336.911 104.597Z'
          fill={elColor[1]}
          style={{ transition: 'fill .5s ease-in' }}
        />
        <rect x='352.086' y='103.111' width='48' height='5' rx='2.5' fill='white' fillOpacity='0.09' />
        <g filter='url(#filter7_b_218_1517)'>
          <circle cx='289.103' cy='198.11' r='31.7432' fill='white' fillOpacity='0.09' />
        </g>
        <rect x='336.086' y='180.111' width='77' height='17' rx='8.5' fill='white' fillOpacity='0.09' />
        <path
          d='M336.911 208.597C336.541 207.93 337.023 207.111 337.785 207.111H344.386C345.149 207.111 345.631 207.93 345.261 208.597L341.96 214.538C341.579 215.224 340.593 215.224 340.212 214.538L336.911 208.597Z'
          fill={elColor[3]}
          style={{ transition: 'fill .5s ease-in' }}
        />
        <rect x='352.086' y='207.111' width='48' height='5' rx='2.5' fill='white' fillOpacity='0.09' />
      </g>
      <defs>
        <filter
          id='filter0_f_218_1517'
          x='498.5'
          y='0'
          width='510.5'
          height='396.223'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feBlend mode='normal' in='SourceGraphic' in2='BackgroundImageFix' result='shape' />
          <feGaussianBlur stdDeviation='7' result='effect1_foregroundBlur_218_1517' />
        </filter>
        <filter
          id='filter1_b_218_1517'
          x='520.116'
          y='30.624'
          width='126.971'
          height='126.973'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feGaussianBlur in='BackgroundImageFix' stdDeviation='15.8716' />
          <feComposite in2='SourceAlpha' operator='in' result='effect1_backgroundBlur_218_1517' />
          <feBlend mode='normal' in='SourceGraphic' in2='effect1_backgroundBlur_218_1517' result='shape' />
        </filter>
        <filter
          id='filter2_b_218_1517'
          x='520.116'
          y='134.624'
          width='126.971'
          height='126.973'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feGaussianBlur in='BackgroundImageFix' stdDeviation='15.8716' />
          <feComposite in2='SourceAlpha' operator='in' result='effect1_backgroundBlur_218_1517' />
          <feBlend mode='normal' in='SourceGraphic' in2='effect1_backgroundBlur_218_1517' result='shape' />
        </filter>
        <filter
          id='filter3_b_218_1517'
          x='-31.7432'
          y='-17.7432'
          width='545.986'
          height='431.709'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feGaussianBlur in='BackgroundImageFix' stdDeviation='15.8716' />
          <feComposite in2='SourceAlpha' operator='in' result='effect1_backgroundBlur_218_1517' />
          <feBlend mode='normal' in='SourceGraphic' in2='effect1_backgroundBlur_218_1517' result='shape' />
        </filter>
        <filter
          id='filter4_b_218_1517'
          x='7.61617'
          y='30.624'
          width='126.971'
          height='126.973'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feGaussianBlur in='BackgroundImageFix' stdDeviation='15.8716' />
          <feComposite in2='SourceAlpha' operator='in' result='effect1_backgroundBlur_218_1517' />
          <feBlend mode='normal' in='SourceGraphic' in2='effect1_backgroundBlur_218_1517' result='shape' />
        </filter>
        <filter
          id='filter5_b_218_1517'
          x='7.61617'
          y='134.624'
          width='126.971'
          height='126.973'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feGaussianBlur in='BackgroundImageFix' stdDeviation='15.8716' />
          <feComposite in2='SourceAlpha' operator='in' result='effect1_backgroundBlur_218_1517' />
          <feBlend mode='normal' in='SourceGraphic' in2='effect1_backgroundBlur_218_1517' result='shape' />
        </filter>
        <filter
          id='filter6_b_218_1517'
          x='225.616'
          y='30.624'
          width='126.971'
          height='126.973'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feGaussianBlur in='BackgroundImageFix' stdDeviation='15.8716' />
          <feComposite in2='SourceAlpha' operator='in' result='effect1_backgroundBlur_218_1517' />
          <feBlend mode='normal' in='SourceGraphic' in2='effect1_backgroundBlur_218_1517' result='shape' />
        </filter>
        <filter
          id='filter7_b_218_1517'
          x='225.616'
          y='134.624'
          width='126.971'
          height='126.973'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feGaussianBlur in='BackgroundImageFix' stdDeviation='15.8716' />
          <feComposite in2='SourceAlpha' operator='in' result='effect1_backgroundBlur_218_1517' />
          <feBlend mode='normal' in='SourceGraphic' in2='effect1_backgroundBlur_218_1517' result='shape' />
        </filter>
      </defs>
    </svg>
  )
}
