import React, { FC, useEffect, useState } from 'react'
import styles from './YouMayImproveResearch.module.scss'
import { useInView } from 'react-intersection-observer'
import clsx from 'clsx'
import { SubscribeComponent } from '@components/SubscribeComponent'
import { ReactComponent as StarsEmoji } from '../../assets/icons/starsEmoji.svg'

export const YouMayImproveResearch: FC = () => {
  const [ref, inView, entry] = useInView({ threshold: 1 })
  const [isAnimActivated, setIsAnimActivated] = useState(false)
  const [isEmailSent, setIsEmailSent] = useState(false)

  useEffect(() => {
    if (inView && !isAnimActivated) {
      setIsAnimActivated(true)
    }
  }, [inView])

  return (
    <div ref={ref} className={styles.wrapper}>
      <div className={styles.header}>
        <span className={styles.headerDot} />
        <span className={styles.headerDot} />
        <span className={styles.headerDot} />
      </div>
      {!isEmailSent ? (
        <>
          <div className={styles.textWrapper}>
            <div className={clsx(styles.instructionPopup, isAnimActivated && styles.animActivated)}>
              мы продолжаем проводить интервью
              <br />и обогащать данные до насыщения
            </div>
            <h6 className={styles.subTitle}>вы можете</h6>
            <h5 className={styles.title}>улучшить</h5>
            <h5 className={styles.title}>исследование</h5>
          </div>
          <div className={styles.subscribeWrapper}>
            <SubscribeComponent isResearch btnTitle='получить ссылку' afterSendHandler={() => setIsEmailSent(true)} />
          </div>
        </>
      ) : (
        <div className={styles.success}>
          <div className={styles.textWrapper}>
            <h5 className={styles.title}>спасибо</h5>
            <h5 className={styles.title}>за участие!</h5>
            <div className={styles.descWrapper}>
              <StarsEmoji className={styles.starsIcon} />
              <p className={styles.desc}>
                мы уже выслали письмо с инструкицей
                <br />
                на указанную почту
              </p>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
