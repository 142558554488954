import React, { FC, useEffect, useRef, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import styles from './WhoBenefits.module.scss'
import clsx from 'clsx'
import { ReactComponent as HappyCatEmoji } from '../../assets/icons/happyCatEmoji.svg'
import { ReactComponent as LaughingCatEmoji } from '../../assets/icons/laughingCatEmoji.svg'
import { ReactComponent as LovelyCatEmoji } from '../../assets/icons/lovelyCatEmoji.svg'
import { ReactComponent as SneeringCatEmoji } from '../../assets/icons/sneeringCatEmoji.svg'
import { ReactComponent as WonderingCatEmoji } from '../../assets/icons/wonderingCatEmoji.svg'
import { ReactComponent as WhoBenefitsPopupArrow } from '../../assets/icons/whoBenefitsPopupArrow.svg'

export const WhoBenefits: FC = () => {
  const [ref, inView, entry] = useInView({})
  const [isAnimActivated, setIsAnimActivated] = useState(false)
  const [elNumber, setElNumber] = useState(0)
  const interval = useRef(null)

  const getRandomNumber = (min, max) => {
    return Math.round(Math.random() * (max - min) + min)
  }

  const moveActivateHandler = () => {
    const randomItem = getRandomNumber(1, 5)
    setElNumber(randomItem)
  }

  useEffect(() => {
    if (inView) {
      interval.current = setInterval(moveActivateHandler, 2000)
    } else {
      clearInterval(interval.current)
    }

    return () => {
      clearInterval(interval.current)
    }
  }, [inView])

  useEffect(() => {
    if (inView && !isAnimActivated) {
      setIsAnimActivated(true)
    }
  }, [inView])

  useEffect(() => {
    if (inView && !isAnimActivated) {
      setIsAnimActivated(true)
    }
  }, [inView])

  return (
    <div ref={ref} className={styles.wrapper} id='whoBenefits'>
      <h5 className={clsx(styles.title, isAnimActivated && styles.animActivated)}>
        <span className={styles.normal}>кому</span>
        <br />
        <span className={styles.transparent}>исследование</span>
        <br />
        <span className={styles.normal}>полезно</span>
      </h5>
      <div
        className={clsx(styles.popupDirectors, isAnimActivated && styles.animActivated, elNumber === 1 && styles.move)}
      >
        <WhoBenefitsPopupArrow className={styles.arrow} />
        <div className={styles.emojiWrapper}>
          <LovelyCatEmoji />
        </div>
        директорам
        <br />
        по маркетингу
      </div>
      <div
        className={clsx(styles.popupEcommerce, isAnimActivated && styles.animActivated, elNumber === 2 && styles.move)}
      >
        <WhoBenefitsPopupArrow className={styles.arrow} />
        <div className={styles.emojiWrapper}>
          <SneeringCatEmoji />
        </div>
        eCommerce-
        <br />
        директорам
      </div>
      <div
        className={clsx(styles.popupAgencies, isAnimActivated && styles.animActivated, elNumber === 3 && styles.move)}
      >
        <WhoBenefitsPopupArrow className={styles.arrow} />
        <div className={styles.emojiWrapper}>
          <WonderingCatEmoji />
        </div>
        агентствам
        <br />и фрилансерам
      </div>
      <div
        className={clsx(styles.popupFounders, isAnimActivated && styles.animActivated, elNumber === 4 && styles.move)}
      >
        <WhoBenefitsPopupArrow className={styles.arrow} />
        <div className={styles.emojiWrapper}>
          <LaughingCatEmoji />
        </div>
        основателям
        <br />
        компаний
      </div>
      <div className={clsx(styles.popupCEOs, isAnimActivated && styles.animActivated, elNumber === 5 && styles.move)}>
        <WhoBenefitsPopupArrow className={styles.arrow} />
        <div className={styles.emojiWrapper}>
          <HappyCatEmoji />
        </div>
        генеральным
        <br />
        директорам
      </div>
    </div>
  )
}
