import React, { FC } from 'react'
import styles from './Numbers.module.scss'
import { ReactComponent as LinkArrow } from '../../assets/icons/linkArrow.svg'
import useWindowSize from '@lib/hooks/useWindowSizeHook'

export const Numbers: FC<{ CTADrawerOpenHandler: () => void }> = ({ CTADrawerOpenHandler }) => {
  const { width } = useWindowSize()
  return (
    <div className={styles.wrapper}>
      <div className={styles.interviewBlock}>
        <h3 className={styles.interviewBlockTitle}>47</h3>
        <h4 className={styles.interviewBlockSubTitle}>
          часов глубинных
          <br />
          интервью
        </h4>
        <p className={styles.interviewBlockDesc}>
          с&nbsp;гендиректорами,
          <br />
          основателями, директорами
          <br />
          по маркетингу и&nbsp;eCommerce
        </p>
      </div>
      <div className={styles.companiesBlock}>
        <h3 className={styles.companiesBlockTitle}>39</h3>
        <h4 className={styles.companiesBlockSubTitle}>
          российских <br />
          компаний
        </h4>
      </div>
      <button onClick={CTADrawerOpenHandler} className={styles.incitesBlockBtn}>
        <h3 className={styles.incitesBlockBtnTitle}>24</h3>
        <h4 className={styles.incitesBlockBtnSubTitle}>
          страницы <br />с инсайтами
        </h4>
        <div className={styles.incitesBlockBtnPseudoBtn}>получить {width >= 850 && 'pdf'}</div>
      </button>
      <div className={styles.linkBlock}>
        <p className={styles.linkDesc}>
          есть вопрос об исследовании <br />
          или хотите поучаствовать?
        </p>
        <a className={styles.link} href='mailto:research@antro.cx' target='_blank' rel='noreferrer'>
          <span>research@antro.cx</span>
          <LinkArrow />
        </a>
      </div>
    </div>
  )
}
