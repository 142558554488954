import React, { FC, useEffect, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import styles from './WhatHappened.module.scss'
import clsx from 'clsx'
import { Crisis } from './components/Crisis'
import { Changes } from './components/Changes'
import { WeResearch } from './components/WeResearch'
/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
/* @ts-ignore */
// import testVideo from '../../assets/video/testvideo.mp4'
// import ReactPlayer from 'react-player'
// import { ReactComponent as PlayBtn } from '../../assets/icons/playBtn.svg'
// import { ReactComponent as PauseBtn } from '../../assets/icons/pauseBtn.svg'

export const WhatHappened: FC = () => {
  const [ref, inView] = useInView({ threshold: 1 })
  const [isAnimActivated, setIsAnimActivated] = useState(false)
  const [isPlaying, setIsPlaying] = useState(false)

  useEffect(() => {
    if (inView && !isAnimActivated) {
      setIsAnimActivated(true)
    }
  }, [inView])

  return (
    <div className={styles.wrapper} id='reason'>
      <div ref={ref} className={styles.leftSide}>
        <h4 className={clsx(styles.leftSideTitle, isAnimActivated && styles.animActivated)}>
          <span className={styles.transparent}>февраль</span>
          <br /> <span className={styles.transparent}>и сентябрь</span>
          <br />
          <span className={styles.transparent}>2022 года</span>
          <br />
          <span className={styles.normal}>изменили</span>
          <br />
          <span className={styles.normal}>реальность</span>
        </h4>
        {/* <div className={styles.videoBlock}>
          <div className={styles.playBtnVideoWrapper}>
            <div className={styles.playerWrapper}>
              <ReactPlayer
                className={styles.player}
                width='auto'
                height='100%'
                url={testVideo}
                playing={isPlaying}
                controls={false}
                loop
                // onProgress={(e)=>dispatch(handleProgress(e))}
                config={{
                  file: {
                    attributes: {
                      controlsList: 'nofullscreen',
                    },
                  },
                }}
              />
            </div>
            {isPlaying ? (
              <PauseBtn className={styles.playBtn} onClick={() => setIsPlaying(false)} />
            ) : (
              <PlayBtn className={styles.playBtn} onClick={() => setIsPlaying(true)} />
            )}
          </div>

          <div className={styles.authorWrapper}>
            <h5 className={styles.authorText}>
              о контексте
              <br />
              исследования
              <br />
              за минуту
            </h5>
            <p className={styles.authorName}>
              женя князев,
              <br />
              CEO Antro
            </p>
          </div>
        </div> */}
      </div>
      <div className={styles.rightSide}>
        <Crisis />
        <Changes />
        <WeResearch />
      </div>
    </div>
  )
}
