import React, { FC, useEffect, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import styles from './Changes.module.scss'
import { ChangesBackground } from './background/ChangesBackground'

export const Changes: FC = () => {
  const [ref, inView] = useInView()
  const [isAnimActivated, setIsAnimActivated] = useState(false)

  useEffect(() => {
    if (inView && !isAnimActivated) {
      setIsAnimActivated(true)
    } else {
      setIsAnimActivated(false)
    }
  }, [inView])

  return (
    <div ref={ref} className={styles.wrapper}>
      <ChangesBackground anim={isAnimActivated} className={styles.background} />
      <h5 className={styles.title}>
        мы изучили
        <br />
        <span className={styles.cyan}>самое важное</span>
      </h5>
      <p className={styles.desc}>
        разобрались,
        <br />
        как оптимизируют косты,
        <br />
        расставляют приоритеты
        <br />и куда инвестируют деньги,
        <br />
        чтобы помочь компаниям
        <br />
        расти в 2023 году
      </p>
    </div>
  )
}
